<app-navbar></app-navbar> 
<div class="max-w-5xl mx-auto mt-28">
    <div class="mx-5 flex-1 text-center">
      <h2 class="text-5xl font-bold mb-10 md:mt-14 maemona-headline">Datenschutz</h2>
      <p class="mx-auto max-w-3xl font-light mb-10 text-xl">Wir freuen uns über Ihr Interesse an unserer Webseite. Der Schutz Ihrer Privatsphäre ist für uns sehr wichtig. Nachstehend informieren wir Sie ausführlich über den Umgang mit Ihren Daten.</p>
    </div>
  </div>
  <svg class="w-full -mb-2" viewBox="0 0 960 30" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><rect x="0" y="0" width="960" height="30" fill="#f3f4f6"></rect><path d="M0 9L17.8 9.2C35.7 9.3 71.3 9.7 106.8 9.5C142.3 9.3 177.7 8.7 213.2 7.8C248.7 7 284.3 6 320 6.7C355.7 7.3 391.3 9.7 426.8 10.5C462.3 11.3 497.7 10.7 533.2 10C568.7 9.3 604.3 8.7 640 9C675.7 9.3 711.3 10.7 746.8 10.3C782.3 10 817.7 8 853.2 7.3C888.7 6.7 924.3 7.3 942.2 7.7L960 8L960 0L942.2 0C924.3 0 888.7 0 853.2 0C817.7 0 782.3 0 746.8 0C711.3 0 675.7 0 640 0C604.3 0 568.7 0 533.2 0C497.7 0 462.3 0 426.8 0C391.3 0 355.7 0 320 0C284.3 0 248.7 0 213.2 0C177.7 0 142.3 0 106.8 0C71.3 0 35.7 0 17.8 0L0 0Z" fill="#ffffff" stroke-linecap="round" stroke-linejoin="miter"></path></svg> 
  <div class="bg-gray-100 pt-10 pb-10 px-5 md:px-10 text-center" id="for-all">
    <p class="text-lg font-bold text-black hyphens-auto">1. Zugriffsdaten und Hosting</p>
    <p class="mx-auto max-w-3xl font-light mb-5 text-lg">Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Bei jedem Zugriff auf unsere Webseite werden Nutzungsdaten durch den jeweiligen Internetbrowser übermittelt und in Protokolldateien, den sogenannten Server-Logfiles, gespeichert. Zu diesen gespeicherten Daten gehören z.B. Name der aufgerufenen Seite, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und der anfragende Provider. Diese Daten dienen ausschließlich der Gewährleistung eines störungsfreien Betriebs unserer Webseite und zur Verbesserung unseres Angebots. Eine Zuordnung dieser Daten zu einer bestimmten Person ist nicht möglich.</p>
    <p class="text-lg font-bold text-black hyphens-auto">2. Datenerhebung und -verwendung zur Vertragsabwicklung</p>
    <p class="mx-auto max-w-3xl font-light mb-5 text-lg">Unsere Webseite erhebt und verarbeitet keine personenbezogenen Daten von uneingeloggten Besuchern. Personenbezogene Daten werden nur dann erhoben, wenn Bestattungsfachkräfte sich anmelden und Anzeigen erstellen oder bearbeiten. Wir verwenden die von Ihnen mitgeteilten Daten zur Vertragsabwicklung und Bearbeitung Ihrer Anfragen. Nach vollständiger Abwicklung des Vertrages oder Löschung Ihres Kundenkontos werden Ihre Daten für die weitere Verarbeitung eingeschränkt und nach Ablauf der steuer- und handelsrechtlichen Aufbewahrungsfristen gelöscht, sofern Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns eine darüberhinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie nachstehend informieren.</p>
    <p class="text-lg font-bold text-black hyphens-auto">3. Datenweitergabe</p>
    <p class="mx-auto max-w-3xl font-light mb-5 text-lg">Eine Weitergabe Ihrer Daten an Dritte ohne Ihre ausdrückliche Einwilligung erfolgt nicht. Ausgenommen hiervon sind lediglich unsere Dienstleistungspartner, die wir zur Abwicklung des Vertragsverhältnisses benötigen. In diesen Fällen beachten wir strikt die Vorgaben des Bundesdatenschutzgesetzes. Der Umfang der Datenübermittlung beschränkt sich auf ein Mindestmaß.</p>
    <p class="text-lg font-bold text-black hyphens-auto">4. Cookies</p>
    <p class="mx-auto max-w-3xl font-light mb-5 text-lg">Unsere Webseite verwendet Cookies. Cookies sind kleine Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem eines Nutzers gespeichert werden. Ruft ein Nutzer eine Webseite auf, so kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der Webseite ermöglicht.</p>
    <p class="text-lg font-bold text-black hyphens-auto">5. Änderung unserer Datenschutzbestimmungen</p>
    <p class="mx-auto max-w-3xl font-light mb-5 text-lg">Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.</p>
    <p class="text-lg font-bold text-black hyphens-auto">6. Fragen an den Datenschutzbeauftragten</p>
    <p class="mx-auto max-w-3xl font-light mb-5 text-lg">Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation:<br><br>
      Florian Werner<br>
      Marquardsenstr. 4<br>
      91054 Erlangen<br>
      Deutschland<br>
      E-Mail: kontakt[ät]maemona.de
    </p>
  </div>
<app-footer></app-footer>