import { Injectable, inject } from '@angular/core';
import { User } from '../interfaces/user';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  url = environment.apiUrl + "users";
  authService: AuthService = inject(AuthService)
  apiKey = localStorage.getItem("subUserId")!;

  constructor() { }

  async getAllUsers(): Promise<User[]> {
    try {
      const response = await fetch(this.url, {
        headers: {
          'x-api-key': this.apiKey
        }
      });
      const data = await response.json();
      return data.items ?? [];
    } catch (error) {
      console.error('Fehler beim Abrufen der Benutzer:', error);
      return [];
    }
  }
  
  async getAllUsersForCompany(companyId: string, skip?: number, limit?: number): Promise<any> {
    try {
      let url = `${this.url}?companyId=${companyId}`;
      if (skip !== undefined) {
        url += `&skip=${skip}`;
      }
      if (limit !== undefined) {
        url += `&limit=${limit}`;
      }
      const response = await fetch(url, {
        headers: {
          'x-api-key': this.apiKey
        }
      });
      const data = await response.json();
      return data ?? [];
    } catch (error) {
      console.error('Fehler beim Abrufen der Benutzer für das Unternehmen:', error);
      return [];
    }
  }
  
  async getUserById(id: string): Promise<User | undefined> {
    try {
      const response = await fetch(`${this.url}/${id}`, {
        headers: {
          'x-api-key': this.apiKey
        }
      });
      const data = await response.json();
      return data ?? {};
    } catch (error) {
      console.error('Fehler beim Abrufen des Benutzers nach ID:', error);
      return undefined;
    }
  }
  

  async deleteUser(id: string): Promise<void> {
    const response = await fetch(`${this.url}/${id}`, {
      method: 'DELETE',
      headers: {
        'x-api-key': this.apiKey
      }
    });
  }

  async createUser(companyId: string, role: string, email: string, password: string, firstName: string, lastName: string, verified: boolean): Promise<void> {  
    const user: User = {
      companyId: companyId,
      role: role,
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      verified: verified
    };
    await fetch(this.url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': this.apiKey
      },
      body: JSON.stringify(user)
    });
  }



  async editUser(userId: string, companyId: string, role: string, email: string, password: string, firstName: string, lastName: string, verified: boolean): Promise<void> {    
    const user: User = {
      companyId: companyId,
      role: role,
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      verified: verified
    };
    await fetch(`${this.url}/${userId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': this.apiKey
      },
      body: JSON.stringify(user)
    });
  }
}
