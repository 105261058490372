import { Routes } from '@angular/router';
import { LoginComponent } from './components/app/mutual/login/login.component';
import { LandingComponent } from './components/website/landing/landing.component';
import { ImprintComponent } from './components/website/imprint/imprint.component';
import { DataProtectionComponent } from './components/website/data-protection/data-protection.component';
import { TermsConditionsComponent } from './components/website/terms-conditions/terms-conditions.component';
import { SupportComponent } from './components/website/support/support.component';
import { UndertakerLandingComponent } from './components/website/undertaker-landing/undertaker-landing.component';
import { UndertakerDashboardComponent } from './components/app/undertaker/undertaker-dashboard/undertaker-dashboard.component';
import { UndertakerAnnouncementsComponent } from './components/app/undertaker/undertaker-announcements/undertaker-announcements.component';
import { UndertakerTeamComponent } from './components/app/undertaker/undertaker-team/undertaker-team.component';
import { AdminDashboardComponent } from './components/app/admin/admin-dashboard/admin-dashboard.component';
import { InstitutesComponent } from './components/app/admin/institutes/institutes.component';
import { AdminTeamComponent } from './components/app/admin/admin-team/admin-team.component';
import { AdminAnnouncementsComponent } from './components/app/admin/admin-announcements/admin-announcements.component';
import { InstituteDeatailsComponent } from './components/app/admin/institute-deatails/institute-deatails.component';
import { ShareLandingComponent } from './components/website/share-landing/share-landing.component';

const routeConfig: Routes = [
    {
      path: 'login',
      component: LoginComponent,
      title: 'mæmona - Login'
    },
    {
      path: 'dashboard',
      component: UndertakerDashboardComponent,
      title: 'mæmona - Dashboard'
    },
    {
      path: 'traueranzeigen',
      component: UndertakerAnnouncementsComponent,
      title: 'mæmona - Traueranzeigen'
    },
    {
      path: 'team',
      component: UndertakerTeamComponent,
      title: 'mæmona - Team'
    },
    {
      path: 'admin-dashboard',
      component: AdminDashboardComponent,
      title: 'mæmona - Dashboard'
    },
    {
      path: 'admin-traueranzeigen',
      component: AdminAnnouncementsComponent,
      title: 'mæmona - Traueranzeigen'
    },
    {
      path: 'bestattungsinstitute',
      component: InstitutesComponent,
      title: 'mæmona - Bestattungsinstitute'
    },
    {
      path: 'admin-team',
      component: AdminTeamComponent,
      title: 'mæmona - Team'
    },
    {
      path: 'bestattungsinstitute/:id',
      component: InstituteDeatailsComponent,
      title: 'mæmona - Unternehmen'
    },
    {
      path: '',
      component: LandingComponent,
      title: 'mæmona - Moderne Traueranzeigen'
    },
    {
      path: 'fuer-bestattungsinstitute',
      component: UndertakerLandingComponent,
      title: 'mæmona - Moderne Traueranzeigen'
    },
    {
      path: 'impressum',
      component: ImprintComponent,
      title: 'mæmona - Impressum'
    },
    {
      path: 'datenschutz',
      component: DataProtectionComponent,
      title: 'mæmona - Datenschutz'
    },
    {
      path: 'nutzungsbedingungen',
      component: TermsConditionsComponent,
      title: 'mæmona - Nutzungsbedingungen'
    },
    {
      path: 'hilfe',
      component: SupportComponent,
      title: 'mæmona - Hilfe'
    },
    {
      path: 'announcement-detail/:id',
      component: ShareLandingComponent,
      title: 'mæmona - Moderne Traueranzeigen'
    },
  ];
  
  export default routeConfig;