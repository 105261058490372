<div *ngIf="this.authService.currentUserSignal()?.role === 'undertaker'">
    <app-sidebar></app-sidebar>

    <div class="ml-96 mr-24 m-12">
        <div class="flex flex-row items-center mt-12">
            <h3 class="text-3xl font-semibold mb-5">Teammitglieder</h3>
            <a class="text-xs bg-transparent text-black border border-gray-500 rounded-3xl py-2 px-4 ml-4 -mt-5 font-normal hover:bg-black hover:border-transparent hover:text-white duration-300" href="mailto:kontakt@maemona.de?subject=Anfrage zusätzliches Teammitglied mæmona">Neues Mitglied</a>
        </div>  
        <p-table [value]="userList" [tableStyle]="{ 'min-width': '50rem' }" [paginator]="true" [rows]="5" [rowsPerPageOptions]="rowsPerPageOptions" [lazy]="true" (onLazyLoad)="fetchUsers($event)" [totalRecords]="totalRecords">
            <ng-template pTemplate="header">
                <tr>
                    <th>Name</th>
                    <th>E-Mail</th>
                    <th>Angelet am</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-user>
                <tr>
                    <td>{{ user.firstName }} {{ user.lastName }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.createdAt | customDateFormat: 'dateTime' }}</td>
                </tr>
            </ng-template>
        </p-table>
        <br>
    </div>

    <p-toast></p-toast>

</div>
