<div *ngIf="this.authService.currentUserSignal()?.role === 'admin'">
    <section>
        <form [formGroup]="announcementForm" (submit)="addOrEditAnnouncement(false)">
            <div class="flex mb-3">
                <div class="w-full pr-2 text-input">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="first-name">Vorname</label>
                    <input class="border mb-3 mr-3" id="first-name" type="text" pInputText formControlName="firstName" placeholder="Vorname"/>
                </div>
                <div class="w-full text-input pl-2">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="last-name">Nachname</label>
                    <input class="border mb-3" id="last-name" type="text" pInputText formControlName="lastName" placeholder="Nachname"/>
                </div>
            </div>
            <div class="flex mb-6">
                <div class="w-full pr-2">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="day-of-birth">Geburtstag</label>
                    <p-calendar class=" mb-3" id="day-of-birth" appendTo="body" formControlName="dayOfBirth" dateFormat="dd.mm.yy" [maxDate]="maxDate" [defaultDate]="defaultDate" placeholder="Geburtstag"></p-calendar>
                </div>
                <div class="w-full pl-2">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="day-of-death">Todestag</label>
                    <p-calendar class=" mb-3" id="day-of-birth" appendTo="body" formControlName="dayOfDeath" dateFormat="dd.mm.yy" [maxDate]="maxDate" placeholder="Todestag"></p-calendar>
                </div>
            </div>
            <div class="flex mb-3">
                <div class="w-full mr-2">
                    <label class="block text-gray-700 text-sm font-bold mb-2">Postleitzahl</label>
                    <p-autoComplete appendTo="body" formControlName="postalCode" [(ngModel)]="selectedPostalCode" [suggestions]="postalCodeSuggestions" (completeMethod)="searchPostalCode($event)" placeholder="Postleitzahl" (ngModelChange)="onPostalCodeChange()" [class.invalid]="checkInputValidity('postalCode')" [forceSelection]="true" />
                </div>
                <div class="w-full ml-2">
                    <label class="block text-gray-700 text-sm font-bold mb-2">Stadt/Landkreis</label>
                    <p-dropdown appendTo="body" formControlName="city" [options]="districtOptions" placeholder="Auswählen"></p-dropdown>
                </div>
            </div>
            <div class="mt-5 mx-auto max-w-32">
                <button  class="bg-black text-white rounded-3xl px-5 py-2 hover:bg-gray-700 duration-300" type="submit">Speichern</button>
            </div>
        </form>
    </section>
</div>

<form [formGroup]="announcementForm" (submit)="addOrEditAnnouncement(false)" *ngIf="this.authService.currentUserSignal()?.role === 'undertaker'">
    <p-stepper [linear]="true">
        <p-stepperPanel header="Persönliche Daten">
            <ng-template pTemplate="content" let-nextCallback="nextCallback" let-index="index">
                
                    <div class="flex mb-3">
                        <div class="w-full pr-2 text-input">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="first-name">Vorname*</label>
                            <input class="border mb-3 mr-3" id="first-name" type="text" pInputText formControlName="firstName" [class.invalid]="checkInputValidity('firstName')"/>
                        </div>
                        <div class="w-full text-input pl-2">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="last-name">Nachname*</label>
                            <input class="border mb-3" id="last-name" type="text" pInputText formControlName="lastName"[class.invalid]="checkInputValidity('lastName')"/>
                        </div>
                    </div>
                    <div class="flex mb-6">
                        <div class="w-full pr-2">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="day-of-birth">Geburtstag*</label>
                            <p-calendar class=" mb-3" id="day-of-birth" appendTo="body" formControlName="dayOfBirth" dateFormat="dd.mm.yy" [maxDate]="maxDate" [defaultDate]="defaultDate" [class.invalid]="checkInputValidity('dayOfBirth')"></p-calendar>
                        </div>
                        <div class="w-full pl-2">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="day-of-death">Todestag*</label>
                            <p-calendar class=" mb-3" id="day-of-birth" appendTo="body" formControlName="dayOfDeath" dateFormat="dd.mm.yy" [maxDate]="maxDate" [class.invalid]="checkInputValidity('dayOfDeath')"></p-calendar>
                        </div>
                    </div>
                    <div class="flex mb-3">
                        <div class="w-full mr-2">
                            <label class="block text-gray-700 text-sm font-bold mb-2">Postleitzahl*</label>
                            <p-autoComplete appendTo="body" formControlName="postalCode" [(ngModel)]="selectedPostalCode" [suggestions]="postalCodeSuggestions" (completeMethod)="searchPostalCode($event)" (ngModelChange)="onPostalCodeChange()" [class.invalid]="checkInputValidity('postalCode')" [forceSelection]="true" />
                        </div>
                        <div class="w-full ml-2">
                            <label class="block text-gray-700 text-sm font-bold mb-2">Stadt/Landkreis*</label>
                            <p-dropdown appendTo="body" formControlName="city" [options]="districtOptions" placeholder="Auswählen" [class.invalid]="checkInputValidity('city')"></p-dropdown>
                        </div>
                    </div>
                    <!-- <div class="flex mb-3">
                        <div class="w-full">
                            <label class="block text-gray-700 text-sm font-bold mb-2">Religionsgemeinschaft</label>
                            <p-autoComplete appendTo="body" formControlName="religion" [(ngModel)]="selectedReligion" [suggestions]="religionSuggestions" (completeMethod)="searchReligion($event)" [dropdown]="true"  placeholder="Eingeben oder auswählen" [forceSelection]="true" />
                            <p-dropdown appendTo="body" formControlName="religion" [options]="religions" placeholder="Auswählen"></p-dropdown>
                        </div>
                    </div> -->
                <div class="flex pt-4 justify-end" *ngIf="checkRequiredInputsvalidity()">
                    <p-button class="bg-black text-white rounded-3xl px-5 py-2 hover:bg-gray-700 duration-300" label="Weiter" icon="pi pi-arrow-right" iconPos="right" (onClick)="nextCallback.emit()" />
                </div>
                <div class="flex pt-4 justify-end" *ngIf="!checkRequiredInputsvalidity()">
                    <p-button class="bg-black text-white rounded-3xl px-5 py-2 hover:bg-gray-700 duration-300" [disabled]="true" label="Weiter" icon="pi pi-arrow-right" iconPos="right" (onClick)="nextCallback.emit()" />
                </div>
            </ng-template>
        </p-stepperPanel>
        <p-stepperPanel header="Text & Medien">
            <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback" let-index="index">
                <div class="flexitems-center mb-5">
                    <label class="block text-gray-700 text-sm font-bold mb-2">Anzeigebild</label>
                    <div class="flex flex-row">
                        <p-fileUpload mode="basic" chooseLabel="Hochladen" name="files[]" url="https://www.primefaces.org/cdn/api/upload.php" accept="image/*" [maxFileSize]="1000000" (onUpload)="onUpload($event)" [auto]="true"></p-fileUpload>
                        <div class="flex items-center ml-4" *ngIf="announcementFileName != ''">
                            <p-image class="object-cover w-12 rounded mx-auto" [src]="url + 'announcementfilesbucket/' + announcementFileName" alt="Vorschau" height="45" *ngIf="announcementFileName !=''" appendTo="body" [preview]="true"></p-image>
                            <p-button icon="pi pi-times" severity="danger" [text]="true" (onClick)="deleteUpload(announcementFileName)"></p-button>
                        </div>
                    </div>
                </div>
                <div class="flexitems-center mb-5">
                    <label class="block text-gray-700 text-sm font-bold mb-2">Zusätzliche Bilder</label>
                    <div class="flex flex-row">
                        <p-fileUpload *ngIf="this.announcementFileArrayNames.length < 5" mode="basic" chooseLabel="Hochladen" name="files[]" url="https://www.primefaces.org/cdn/api/upload.php" accept="image/*" [maxFileSize]="1000000" (onUpload)="onUploadArray($event)" [auto]="true"></p-fileUpload>
                        <p-fileUpload *ngIf="this.announcementFileArrayNames.length > 4"  [disabled]="true" mode="basic" chooseLabel="Hochladen" name="files[]" url="https://www.primefaces.org/cdn/api/upload.php" accept="image/*" [maxFileSize]="1000000" (onUpload)="onUploadArray($event)" [auto]="true"></p-fileUpload>
                        <div *ngIf="announcementFileArrayNames.length != 0">
                            <div class="flex items-center ml-4" *ngFor="let fileName of announcementFileArrayNames">
                                <p-image class="object-cover w-12 rounded mx-auto" [src]="url + 'announcementfilesbucket/' + fileName" alt="Vorschau" height="45" appendTo="body" [preview]="true"></p-image>
                                <p-button icon="pi pi-times" severity="danger" [text]="true" (onClick)="deleteUploadArray(fileName.toString())"></p-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex mb-3">
                    <div class="w-full">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="text">Traueranzeigentext</label>
                        <textarea class="border p-2" rows="5" cols="46" pInputTextarea formControlName="text" id="text"></textarea>
                    </div>
                </div>
                <div class="flex pt-4 justify-between">
                    <p-button class="bg-black text-white rounded-3xl px-5 py-2 hover:bg-gray-700 duration-300" label="Zurück" icon="pi pi-arrow-left" (onClick)="prevCallback.emit()"/>
                    <p-button class="bg-black text-white rounded-3xl px-5 py-2 hover:bg-gray-700 duration-300" label="Weiter" icon="pi pi-arrow-right" iconPos="right" (onClick)="nextCallback.emit()"/>
                </div>
            </ng-template>
        </p-stepperPanel>
        <p-stepperPanel header="Bestattungsinformationen">
            <ng-template pTemplate="content" let-prevCallback="prevCallback" let-index="index">
                <div class="flex mb-3">
                    <div class="w-full pr-2">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="burial-datge">Bestattungstermin</label>
                        <p-calendar class="p-2 mb-3 mr-2 -ml-2" id="burial-date" appendTo="body" formControlName="burialDate" dateFormat="dd.mm.yy" [showButtonBar]="true" [showTime]="true" [minDate]="minDate"></p-calendar>
                    </div>
                    <div class="w-full text-input pl-2">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="burial-location">Bestattungsort</label>
                        <p-autoComplete appendTo="body" formControlName="burialLocation" [(ngModel)]="selectedBurialLocation" [suggestions]="burialLocationSuggestions" (completeMethod)="searchBurialLocation($event)" [forceSelection]="true" placeholder="z.B. Zentralfriedhof Erlangen"/>
                    </div>
                </div>
                <div class="flex mb-3">
                    <div class="w-full">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="burialText">Zusätzliche Informationen</label>
                        <textarea class="border p-2" rows="5" cols="46" pInputTextarea formControlName="burialText" id="burialText" placeholder="z.B. Beerdigung nur im Kreis der Familie, Details zum Leichenschmaus, etc."></textarea>
                    </div>
                </div>
                <div class="flex pt-4 justify-between">
                    <p-button class="bg-black text-white rounded-3xl px-5 py-2 hover:bg-gray-700 duration-300" label="Zurück" icon="pi pi-arrow-left" (onClick)="prevCallback.emit()" />
                    <p-button class="bg-gray-100 text-black rounded-3xl px-5 py-2 hover:bg-gray-400 duration-300" label="Vorschau" (click)="openPreviewDialog()" />
                    <p-button *ngIf="selectedAnnouncement && selectedAnnouncement.isPublic == true" class="bg-gray-100 text-black rounded-3xl px-5 py-2 hover:bg-gray-400 duration-300" label="Zurückziehen" (click)="addOrEditAnnouncement(true)" />
                    <p-button *ngIf="!selectedAnnouncement || selectedAnnouncement.isPublic == false" class="bg-gray-100 text-black rounded-3xl px-5 py-2 hover:bg-gray-400 duration-300" label="Speichern" (click)="addOrEditAnnouncement(true)" />
                    <button *ngIf="selectedAnnouncement && selectedAnnouncement.isPublic == true" class="bg-black text-white rounded-3xl px-5 py-2 hover:bg-gray-700 duration-300" type="submit">Speichern</button>
                    <button *ngIf="!selectedAnnouncement || selectedAnnouncement.isPublic == false" class="bg-black text-white rounded-3xl px-5 py-2 hover:bg-gray-700 duration-300" type="submit">Veröffentlichen</button>
                </div>
                </ng-template>
        </p-stepperPanel>
    </p-stepper>
</form>

<p-dialog [(visible)]="isPreviewDialogVisible" [style]="{ 'width.px': '500', 'max-height': '800px' }" [modal]="true" [responsive]="true" [draggable]="false">
    <p-header>
        <span>Vorschau</span>
        <button type="button" class="p-dialog-titlebar-icon p-link ml-4" (click)="print()">
            <span class="pi pi-print"></span>
        </button>
    </p-header>
    <app-preview id="printableContent"
        [imageSrc]="printPreviewData.imageSrc" 
        [birthDate]="printPreviewData.birthDate" 
        [deathDate]="printPreviewData.deathDate" 
        [name]="printPreviewData.name" 
        [age]="printPreviewData.age" 
        [text]="printPreviewData.text" 
        [additionalMediaSrc]="printPreviewData.additionalMediaSrc" 
        [burialLocation]="printPreviewData.burialLocation" 
        [burialDate]="printPreviewData.burialDate" 
        [burialText]="printPreviewData.burialText" 
        [undertakerName]="printPreviewData.undertakerName">
    </app-preview>
</p-dialog>