import { Injectable } from '@angular/core';
import { Company } from '../interfaces/company';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  url = environment.apiUrl + "companies"
  apiKey = localStorage.getItem("subUserId")!;

  constructor() { }

  async createCompany(companyName: string, phoneNumber: string, address: string, city: string, postalCode: string, country: string, email: string, website: string): Promise<void> {    
    const company: Company = {
      companyName: companyName,
      phoneNumber: phoneNumber,
      address: address,
      city: city,
      postalCode: postalCode,
      country: country,
      email: email,
      website: website,
    };
    
    await fetch(this.url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': this.apiKey
      },
      body: JSON.stringify(company)
    });
  }

  async editCompany(companyId: string, companyName: string, phoneNumber: string, address: string, city: string, postalCode: string, country: string, email: string, website: string): Promise<void> {    
    const company: Company = {
      companyName: companyName,
      phoneNumber: phoneNumber,
      address: address,
      city: city,
      postalCode: postalCode,
      country: country,
      email: email,
      website: website,
    };
    
    await fetch(`${this.url}/${companyId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': this.apiKey
      },
      body: JSON.stringify(company)
    });
  }

  async getAllCompanies(skip?: number, limit?: number): Promise<any> {
    let url = this.url;
    if (skip !== undefined) {
      url += '?skip=' + skip;
    }
    if (limit !== undefined) {
      url += '&limit=' + limit;
    }
    
    try {
      const response = await fetch(url, {
        headers: {
          'x-api-key': this.apiKey
        }
      });
  
      if (!response.ok) {
        throw new Error('Fehler beim Abrufen der Daten');
      }
  
      const data = await response.json();
      return data ?? [];
    } catch (error) {
      console.error('Fehler beim Abrufen der Daten:', error);
      return null;
    }
  }
  

  async getCompanyById(id: string): Promise<Company | undefined> {
    try {
      const response = await fetch(`${this.url}/${id}`, {
        headers: {
          'x-api-key': this.apiKey
        }
      });
  
      if (!response.ok) {
        throw new Error('Fehler beim Abrufen der Daten');
      }
  
      const data = await response.json();
      return data[0] ?? {};
    } catch (error) {
      console.error('Fehler beim Abrufen der Daten:', error);
      return undefined;
    }
  }
  

  async deleteCompany(id: string): Promise<void> {
    const response = await fetch(`${this.url}/${id}`, {
      method: 'DELETE',
      headers: {
        'x-api-key': this.apiKey
      },
  });
  }

}
