<div *ngIf="this.authService.currentUserSignal()?.role === 'undertaker'">
    <section class="listing-apply">
        <form [formGroup]="passwordForm" (submit)="changePassword()" class="flex flex-col">
            <input class="border p-2 mb-3 mt-1" id="password" type="password" pInputText formControlName="password" placeholder="Neues Passwort"/>
            <input class="border p-2 mb-3 mt-1" id="password-repeat" type="password" pInputText formControlName="passwordRepeat" placeholder="Passwort wiederholen"/>
            <div class="mx-auto max-w-32">
                <button class="text-white bg-black px-5 py-3 mt-5 items-center rounded-3xl" type="submit">Speichern</button>
            </div>
        </form>
    </section>
    </div>