<div *ngIf="this.authService.currentUserSignal()?.role === 'admin'">
    <section class="listing-apply">
        <form [formGroup]="companyForm" (submit)="addOrEditCompany()" class="flex flex-col">
            <input class="border p-2 mb-3 mt-1" id="company-name" type="text" pInputText formControlName="companyName" placeholder="Unternehmensname"/>
            <input class="border p-2 mb-3" id="phone-number" type="text" pInputText formControlName="phoneNumber" placeholder="Telefonnummer"/>
            <input class="border p-2 mb-3" id="address" type="text" pInputText formControlName="address" placeholder="Adresse"/>
            <!-- <p-dropdown appendTo="body" formControlName="postalCode" [options]="postalCodeOptions" placeholder="Postleitzahl" (onChange)="onPostalCodeChange()"></p-dropdown> -->
            <p-autoComplete class="mb-3" appendTo="body" formControlName="postalCode" [(ngModel)]="selectedPostalCode" [suggestions]="postalCodeSuggestions" (completeMethod)="searchPostalCode($event)" placeholder="Postleitzahl" (ngModelChange)="onPostalCodeChange()" [forceSelection]="true" />
            <p-dropdown appendTo="body" formControlName="city" [options]="cityOptions" placeholder="Stadt/Landkreis"></p-dropdown>
            <input class="border p-2 mb-3" id="country" type="text" pInputText formControlName="country" placeholder="Land"/>
            <input class="border p-2 mb-3" id="email" type="text" pInputText formControlName="email" placeholder="E-Mail"/>
            <input class="border p-2 mb-3" id="website" type="text" pInputText formControlName="website" placeholder="Webseite"/>

            <p-inputSwitch id="website" formControlName="verified"></p-inputSwitch>
            
            <div class="mt-5 mx-auto max-w-32">
                <button class="text-white bg-black px-5 py-3 items-center rounded-3xl" type="submit">Speichern</button>
            </div>
        </form>
    </section>
</div>
