import { Component, Input, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { CustomDateFormatPipe } from '../../../../pipes/custom-date-format.pipe';
import { NgIf } from '@angular/common';
import { User } from '../../../../interfaces/user';
import { Announcement } from '../../../../interfaces/announcement';
import { Company } from '../../../../interfaces/company';
import { AnnouncementsService } from '../../../../services/announcements.service';
import { CompaniesService } from '../../../../services/companies.service';
import { UsersService } from '../../../../services/users.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { AddEditUserComponent } from '../add-edit-user/add-edit-user.component';
import { ImageModule } from 'primeng/image';
import { AddEditAnnouncementComponent } from '../../mutual/add-edit-announcement/add-edit-announcement.component';
import { ToastModule } from 'primeng/toast';
import { environment } from '../../../../../environments/environment';
import { AdminSidebarComponent } from '../admin-sidebar/admin-sidebar.component';

@Component({
  selector: 'app-institute-deatails',
  standalone: true,
  imports: [
    RouterModule,
    TableModule,
    ButtonModule,
    CustomDateFormatPipe,
    NgIf,
    ConfirmDialogModule,
    DialogModule,
    AddEditUserComponent,
    ImageModule,
    AddEditAnnouncementComponent,
    ToastModule,
    AdminSidebarComponent
  ],
  providers: [ConfirmationService, MessageService],
  templateUrl: './institute-deatails.component.html',
  styleUrl: './institute-deatails.component.css'
})
export class InstituteDeatailsComponent {
  @Input() companyId: string = "";
  route: ActivatedRoute = inject(ActivatedRoute);
  companiesService: CompaniesService = inject(CompaniesService)
  usersService: UsersService = inject(UsersService)
  userList: User[] = []
  announcementsService: AnnouncementsService =inject(AnnouncementsService)
  announcementList: Announcement[] = []
  authService: AuthService = inject(AuthService)
  company!: Company;
  isEditDialogVisible = false;
  isAddDialogVisible = false;
  selectedUser: any = null;
  isAnnouncementEditDialogVisible = false;
  selectedAnnouncement: any = null;
  totalRecords!: number;
  rowsPerPageOptions = [5,10,20];
  totalRecordsUser!: number;
  rowsPerPageOptionsUser = [5,10,20];
  url = environment.apiUrl + "announcementfilesbucket/";

  constructor(private router: Router, private messageService: MessageService, private confirmationService: ConfirmationService) {}

  async ngOnInit(): Promise<void> {
    if (this.companyId == ""){
      this.companyId = this.route.snapshot.params['id'];
    }
    this.companiesService.getCompanyById(this.companyId).then((company: Company | undefined) => {
      if (company) {
        this.company = company;
      }
    });
    await this.authService.waitForCurrentUserSignal();
    this.fetchUsers();
    this.fetchAnnouncements();
    if (this.authService.currentUserSignal() == null) {
      this.router.navigate(['/login']);
    } else if (this.authService.currentUserSignal()?.role != 'admin') {
      this.router.navigate(['/dashboard']);
    }
  }

  fetchUsers($event?: TableLazyLoadEvent) {
    if (this.companyId != "" ) {
      this.usersService.getAllUsersForCompany(this.companyId, $event?.first || 0, $event?.rows || 5).then((userData: any) => {
        this.totalRecordsUser = userData.totalItems;
        this.userList = userData.items;
      });
    }
  }

  showAddEditDialog(add: boolean, input: any) {
    if (add) {
      this.isAddDialogVisible = true;
      this.companyId = input;
    } else {
      this.isEditDialogVisible = true;
      this.selectedUser = input;
    }
  }

  onUserEdited() {
    this.fetchUsers();
    this.isEditDialogVisible = false;
    this.messageService.add({ severity: 'success', summary: 'Bearbeiten erfolgreich', detail: "Person wurde bearbeitet" });
  }

  onUserAdded() {
    this.fetchUsers();
    this.isAddDialogVisible = false;
    this.messageService.add({ severity: 'success', summary: 'Anlegen erfolgreich', detail: "Person wurde angelegt" });
  }

  deleteUser(id: string) {
    this.usersService.getUserById(id).then((user: any) => {
      if (user) {
        user = user[0];
        this.confirmationService.confirm({
            message: user.firstName+" "+user.lastName+" wirkllich löschen?",
            acceptLabel: "Ja",
            rejectLabel: "Nein",
            acceptButtonStyleClass: "p-button-danger",
            acceptIcon:"none",
            rejectIcon:"none",
            accept: () => {
              this.usersService.deleteUser(id).then(() => {
                this.fetchUsers();
                this.messageService.add({ severity: 'success', summary: 'Löschen bestätigt', detail: user.firstName+" "+user.lastName+" wurde gelöscht" });
              })
              .catch((error) => {
                console.log(error);
                this.messageService.add({ severity: 'error', summary: 'Fehler beim Löschen', detail: 'Das Löschen von ' + user.firstName + ' ' + user.lastName + ' ist fehlgeschlagen' });
              });
              
            }
        });
      }
    });
  }

  fetchAnnouncements($event?: TableLazyLoadEvent) {
    if (this.companyId != "" ) {
      this.announcementsService.getAllAnnouncementsForCompany(this.companyId, $event?.first || 0, $event?.rows || 5).then((announcementData: any) => {
        this.totalRecords = announcementData.totalItems;
        this.announcementList = announcementData.items.map((announcement: Announcement) => {
          const user = this.userList.find(user => user.userId === announcement.userId);
          return {
            ...announcement,
            userName: user ? user.firstName + ' ' + user.lastName : 'Unbekannter Benutzer'
          };
        });
      })
    }
  }

  showAnnouncementEditDialog(announcement: Announcement, companyId: any) {
    this.isAnnouncementEditDialogVisible = true;
    this.selectedAnnouncement = announcement;
    this.companyId = companyId;
  }

  onAnnouncementEdited() {
    this.fetchAnnouncements();
    this.isAnnouncementEditDialogVisible = false;
    this.messageService.add({ severity: 'success', summary: 'Bearbeiten erfolgreich', detail: "Traueranzeige wurde bearbeitet" });
  }

  deleteAnnouncement(id: string) {
    this.announcementsService.getAnnouncementById(id).then((announcement: any) => {
      if (announcement) {
        announcement = announcement[0];
        this.confirmationService.confirm({
            message: "Traueranzeige für "+announcement.firstName+" "+announcement.lastName+" wirkllich löschen?",
            acceptLabel: "Ja",
            rejectLabel: "Nein",
            acceptButtonStyleClass: "p-button-danger",
            acceptIcon:"none",
            rejectIcon:"none",
            accept: () => {
              this.announcementsService.deleteAnnouncement(id).then(() => {
                this.fetchAnnouncements();
                this.messageService.add({ severity: 'success', summary: 'Löschen bestätigt', detail: "Traueranzeige wurde gelöscht" });
              })
              .catch((error) => {
                console.log(error);
                this.messageService.add({ severity: 'error', summary: 'Fehler beim Löschen', detail: 'Das Löschen der Traueranzeige von ' + announcement.firstName + ' ' + announcement.lastName + ' ist fehlgeschlagen' });
              });
            }
        });
      }
    });
  }
}
