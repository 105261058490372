import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDateFormat',
  standalone: true,
})
export class CustomDateFormatPipe implements PipeTransform {
  transform(value: string, format: string): string {
    if (value && typeof value === 'string') {
      const date = new Date(value);
      if (format === 'dateOnly') {
        return date.toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' });
      } else if (format === 'dateTime') {
        return date.toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' }) +
               ' ' + date.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
      } else {
        return value;
      }
    } else {
      return value;
    }
  }
}