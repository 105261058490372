import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UsersService } from '../../../../services/users.service';
import { NgIf } from '@angular/common';
import { AuthService } from '../../../../services/auth.service';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    InputTextModule
  ],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.css'
})
export class ChangePasswordComponent {

  @Output() passwordChanged: EventEmitter<void> = new EventEmitter<void>();

  usersService: UsersService = inject(UsersService);
  authService: AuthService = inject(AuthService);

  passwordForm = new FormGroup({
    password: new FormControl(''),
    passwordRepeat: new FormControl('')
  });

  changePassword() {
    if (this.passwordForm.value.password == this.passwordForm.value.passwordRepeat) {
      this.usersService.editUser(
        this.authService.currentUserSignal()?.userId!,
        this.authService.currentUserSignal()?.companyId!,
        this.authService.currentUserSignal()?.role!,
        this.authService.currentUserSignal()?.email!,
        this.passwordForm.value.password ?? '',
        this.authService.currentUserSignal()?.firstName!,
        this.authService.currentUserSignal()?.lastName!,
        this.authService.currentUserSignal()?.verified!).then(() => {
          console.log("Password changed")
          this.passwordForm.reset();
          this.passwordChanged.emit();
      });
    }
  }

}
