import { Injectable, signal } from '@angular/core';
import { User } from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentUserSignal = signal<User | undefined | null>(undefined); 

  constructor() { }

  waitForCurrentUserSignal(): Promise<any> {
    return new Promise<any>((resolve) => {
      const interval = 50; // Überprüft alle 50 ms
      const timeout = 3000; // Timeout in Millisekunden (3 Sekunden)
      let elapsedTime = 0;
  
      const checkInterval = setInterval(() => {
        const currentUser = this.currentUserSignal();
        if (currentUser) {
          clearInterval(checkInterval);
          resolve(currentUser);
        } else {
          elapsedTime += interval;
          if (elapsedTime >= timeout) {
            clearInterval(checkInterval);
            resolve(null); // Resolve with null if timeout is reached
          }
        }
      }, interval);
    });
  }

}
