import { Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CurrentRouteService } from '../../../../services/current-route.service';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-admin-sidebar',
  standalone: true,
  imports: [
    RouterModule
  ],
  templateUrl: './admin-sidebar.component.html',
  styleUrl: './admin-sidebar.component.css'
})
export class AdminSidebarComponent {
  constructor(private currentRouteService: CurrentRouteService, private router: Router) {}

  authService: AuthService = inject(AuthService);

  isActive(targetPath: string) {
    return this.currentRouteService.checkPath(targetPath)
  }

  logout() {
    localStorage.setItem("userId", '');
    localStorage.setItem("subUserId", '');
    this.authService.currentUserSignal.set(null);
    this.router.navigate(['/login']);
  }
}
