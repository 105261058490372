<div id="container">
    <img id="image" src="{{imageSrc}}">
    <div id="dates"><p>* {{birthDate}} <span>  </span> † {{deathDate}}</p></div>
    <div id="name"><p>{{ name }}</p></div>
    <div id="age"><p>{{age}} Jahre</p></div>
    <div *ngIf="text != ''" id="text"><p>{{text}}</p></div>
    <div id="share"><i class="fa-solid fa-arrow-up-from-bracket"></i><p>Teilen</p></div>
    <img *ngIf="additionalMediaSrc != 'https://gzca8qrqcl.execute-api.eu-central-1.amazonaws.com/test/announcementfilesbucket/'" id="additional-media" src="{{additionalMediaSrc}}">
    <div *ngIf="burialLocation != '' || burialDate != '31.12.2023, 23:00' || burialText != ''" id="burial-label"><p>BESTATTUNGSDETAILS</p></div>
    <div id="burial">
        <div *ngIf="burialLocation != ''" id="map"><img src="../../../../assets/img/map.png"></div>
        <div *ngIf="burialLocation != ''" id="location"><p>{{burialLocation}}</p></div>
        <hr *ngIf="burialDate != '31.12.2023, 23:00' || burialText != ''">
        <div *ngIf="burialDate != '31.12.2023, 23:00'" id="date"><p>{{burialDate}} Uhr</p></div>
        <hr *ngIf="burialText != ''">
        <div *ngIf="burialText != ''" id="burial-text"><p>{{burialText}}</p></div>
    </div>
    <div id="undertaker">
        <div id="tag"><p>Bestattung durchgeführt von:</p></div>
        <div id="undertaker-name"><p>{{undertakerName}}</p></div>
    </div>
</div>