<div *ngIf="this.authService.currentUserSignal()?.role === 'admin'">
    <app-admin-sidebar></app-admin-sidebar>

    <div class="ml-96 mr-24 m-12">
        <div class="flex flex-row -ml-3">
            <button [routerLink]="['/bestattungsinstitute']" class="mr-2 -mt-5 text-gray-400 hover:text-black duration-300"><svg class="h-10 w-10" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <polyline points="15 18 9 12 15 6" /></svg></button>
            <h2 lang="de" class="text-4xl font-semibold mb-5 break-words hyphens-auto" *ngIf="company != undefined">{{ company.companyName }}</h2>
        </div>
        <div class="flex flex-row items-center mt-10">
            <h3 class="text-3xl font-semibold mb-5">Teammitglieder</h3>
            <button class="text-xs bg-transparent text-black border border-gray-500 rounded-3xl py-2 px-4 ml-4 -mt-5 font-normal hover:bg-black hover:border-transparent hover:text-white duration-300" (click)="showAddEditDialog(true,companyId)">Neues Mitglied</button>
        </div>  
        <p-table [value]="userList" [tableStyle]="{ 'min-width': '50rem' }" [paginator]="true" [rows]="5" [rowsPerPageOptions]="rowsPerPageOptionsUser" [lazy]="true" (onLazyLoad)="fetchUsers($event)" [totalRecords]="totalRecordsUser">
            <ng-template pTemplate="header">
                <tr>
                    <th>Name</th>
                    <th>E-Mail</th>
                    <th>Rolle</th>
                    <th>Angelet am</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-user>
                <tr>
                    <td>{{ user.firstName }} {{ user.lastName }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.role }}</td>
                    <td>{{ user.createdAt | customDateFormat: 'dateTime' }}</td>
                    <td class="flex flex-row">
                        <p-button class="text-gray-600 hover:bg-gray-50 rounded-full px-0 mr-2" icon="pi pi-pencil" severity="secondary" [rounded]="true"  [text]="true" (onClick)="showAddEditDialog(false, user)"></p-button>
                        <p-button class="text-red-600 hover:bg-red-50 rounded-full px-0" icon="pi pi-times" severity="danger" [rounded]="true"  [text]="true" (onClick)="deleteUser(user.userId)"></p-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <div class="flex flex-row items-center mt-10">
            <h3 class="text-3xl font-semibold mb-5">Traueranzeigen</h3>
        </div>  
        <p-table [value]="announcementList" [tableStyle]="{ 'min-width': '50rem' }" [paginator]="true" [rows]="5" [rowsPerPageOptions]="rowsPerPageOptions" [lazy]="true" (onLazyLoad)="fetchAnnouncements($event)" [totalRecords]="totalRecords">
            <ng-template pTemplate="header">
                <tr>
                    <th>Name</th>
                    <th>Hauptbild</th>
                    <th>Stadt/Landkreis</th>
                    <th>Geburtstag</th>
                    <th>Todestag</th>
                    <th>Text</th>
                    <th>Beerdigung</th>
                    <th>Angelet am</th>
                    <th>Angelegt von</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-announcement>
                <tr>
                    <td>{{ announcement.firstName }} {{ announcement.lastName }}</td>
                    <td class="text-center">
                        <div class="w-10 mx-auto rounded-xl m-0 p-0">
                            <p-image [src]="url + announcement.pictureURL" [alt]="'Hauptbild für' + announcement.firstName + ' ' + announcement.lastName" height="50" *ngIf="announcement.pictureURL !=''" [preview]="true"></p-image>
                            <img [src]="url + 'profile-icon.jpg'" alt="Hauptbild" *ngIf="announcement.pictureURL ==''">
                        </div>
                    <td>{{ announcement.postalCode }} {{ announcement.city }}</td>
                    <td>{{ announcement.dayOfBirth | customDateFormat: 'dateOnly' }}</td>
                    <td>{{ announcement.dayOfDeath | customDateFormat: 'dateOnly' }}</td>
                    <td>{{ announcement.text ? (announcement.text.length > 10 ? announcement.text.slice(0, 10) + '...' : announcement.text) : '' }}</td>
                    <td>{{ announcement.burialDate | customDateFormat: 'dateTime' }} {{ announcement.burialLocation }}</td>
                    <td>{{ announcement.createdAt | customDateFormat: 'dateTime'  }}</td>
                    <td>{{ announcement.userName }}</td>
                    <td class="flex flex-row">
                        <p-button class="text-gray-600 hover:bg-gray-50 rounded-full px-0 mr-2" icon="pi pi-pencil" severity="secondary" [rounded]="true" [text]="true" (onClick)="showAnnouncementEditDialog(announcement, companyId)"></p-button>
                        <p-button class="text-red-600 hover:bg-red-50 rounded-full px-0" icon="pi pi-times" severity="danger" [rounded]="true" [text]="true" (onClick)="deleteAnnouncement(announcement.announcementId)"></p-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <p-confirmDialog header="Löschen bestätigen" icon="pi pi-exclamation-triangle"></p-confirmDialog>
    <p-dialog header="Person bearbeiten" [modal]="true" [(visible)]="isEditDialogVisible">
        <app-add-edit-user (userEdited)="onUserEdited()" [selectedUser]="selectedUser"></app-add-edit-user>
    </p-dialog>
    <p-dialog header="Person anlegen" [modal]="true" [(visible)]="isAddDialogVisible">
        <app-add-edit-user (userAdded)="onUserAdded()" [companyId]="companyId"></app-add-edit-user>
    </p-dialog>
    <p-dialog header="Anzeige bearbeiten" [modal]="true" [(visible)]="isAnnouncementEditDialogVisible">
        <app-add-edit-announcement (announcementEdited)="onAnnouncementEdited()" [selectedAnnouncement]="selectedAnnouncement" [companyId]="companyId"></app-add-edit-announcement>
    </p-dialog>
    <p-toast></p-toast>
</div>

