import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

declare var mapkit: any;

@Injectable({
  providedIn: 'root'
})
export class LocationsService {
  url = environment.apiUrl + "locations"
  apiKey = localStorage.getItem("subUserId")!;
  mapKitKey = environment.mapKitKey;

  constructor() {
    mapkit.init({
      authorizationCallback: (done: (arg0: string) => void) => {
        done(this.mapKitKey);
      }
    });
  }

  async searchPostalCodes(searchString: string): Promise<any[]> {
    try {
      const url = `https://openplzapi.org/de/Localities?postalCode=%5E` + searchString + `&page=1&pageSize=50`;
      const response = await fetch(url, {
        headers: {}
      });
      const data = await response.json();
      let postalCodeSet = new Set();
      data.forEach((item: { postalCode: unknown; }) => {
          postalCodeSet.add(item.postalCode);
      });
      let uniquePostalCodes = Array.from(postalCodeSet);
      return uniquePostalCodes ?? [];
    } catch (error) {
      console.error('Fehler beim Abrufen der Postleitzahlen:', error);
      return [];
    }
  }
  
  async getDistrictsByPostalCode(postalcode: string): Promise<string[]> {
    try {
      const url = `https://openplzapi.org/de/Localities?postalCode=%5E` + postalcode + `&page=1&pageSize=50`;
      const response = await fetch(url, {
        headers: {}
      });
      const data = await response.json();
      let districtNameSet: Set<string> = new Set();
      function extractRelevantName(name: string): string {
        if (name.startsWith("Region ")) {
            return name.substring(7).split(',')[0].trim();
        } else {
            return name.split(',')[0].trim();
        }
      }
      data.forEach((item: { district: { name: string; }; municipality: { type: string; name: string; }; }) => {
          let districtName = "";
          if (item.district && item.district.name) {
              districtName = extractRelevantName(item.district.name);
          } else if (item.municipality && item.municipality.type === "Kreisfreie_Stadt") {
              districtName = extractRelevantName(item.municipality.name);
          }
          if (districtName) {
              districtNameSet.add(districtName);
          }
      });
      let uniqueDistrictNames: string[] = Array.from(districtNameSet);
      return uniqueDistrictNames ?? [];
    } catch (error) {
      console.error('Fehler beim Abrufen der Regionen anhand der Postleitzahl:', error);
      return [];
    }
  }

  async getBurialLocations(searchTerm: string): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
        var search = new mapkit.Search({
          limitToCountries: "de",
          includePointsOfInterest: true, 
          includeAddresses: false,
          includePhysicalFeatures: false
        });

        search.autocomplete(searchTerm, (error: any, data: any) => {
            if (error) {
                console.error('Fehler beim Geocoding:', error);
                reject(error);
                return;
            }
            var names = data.results
                .filter((result: { displayLines: string[]; }) => {
                    return !result.displayLines.some(line => line.includes('In der Nähe suchen'));
                })
                .map((result: { displayLines: string[]; }) => {
                    const address = result.displayLines.join(', ');
                    return address;
                });

            resolve(names);
        });
    });
  }

  
  
}
