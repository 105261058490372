import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { UsersService } from '../../../../services/users.service';
import { User } from '../../../../interfaces/user';
import { NgIf } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { AddEditAnnouncementComponent } from '../../mutual/add-edit-announcement/add-edit-announcement.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AnnouncementsService } from '../../../../services/announcements.service';
import { ToastModule } from 'primeng/toast';
import { Announcement } from '../../../../interfaces/announcement';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { SidebarComponent } from '../sidebar/sidebar.component';

@Component({
  selector: 'app-undertaker-dashboard',
  standalone: true,
  imports: [
    RouterModule,
    NgIf,
    DialogModule,
    AddEditAnnouncementComponent,
    ToastModule,
    SidebarComponent
  ],
  providers: [ConfirmationService, MessageService],
  templateUrl: './undertaker-dashboard.component.html',
  styleUrl: './undertaker-dashboard.component.css'
})
export class UndertakerDashboardComponent {
  authService: AuthService = inject(AuthService);
  usersService: UsersService = inject(UsersService);
  announcementsService: AnnouncementsService =inject(AnnouncementsService)
  user!: User;
  userId = ""
  isAnnouncementAddDialogVisible = false;
  companyId = "";
  userList: User[] = [];
  announcementList: Announcement[] = [];

  constructor(private router: Router, private messageService: MessageService) {}

  async ngOnInit(): Promise<void> {
    await this.authService.waitForCurrentUserSignal();
    this.companyId = this.authService.currentUserSignal()?.companyId ?? "";
    this.fetchUsers();
    this.fetchAnnouncements();
    if (this.authService.currentUserSignal() == null) {
      this.router.navigate(['/login']);
    } else if (this.authService.currentUserSignal()?.role != 'undertaker') {
      this.router.navigate(['/admin-team']);
    }
  }

  fetchUsers() {
    if (this.companyId != "" ) {
      this.usersService.getAllUsersForCompany(this.companyId).then((userList: any) => {
        this.userList = userList.items;
      });
    }
  }

  fetchAnnouncements() {
    this.announcementsService.getAllAnnouncementsForCompany(this.companyId).then((announcementList: any) => {
      this.announcementList = announcementList.items;
    });
  }

  onAnnouncementAdded() {
    this.isAnnouncementAddDialogVisible = false;
    this.messageService.add({ severity: 'success', summary: 'Anlegen erfolgreich', detail: "Traueranzeige wurde angelegt" });
  }
}
