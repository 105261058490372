<div *ngIf="this.authService.currentUserSignal()?.role === 'admin'">
    <app-admin-sidebar></app-admin-sidebar>

    <div class="ml-96 mr-24 m-12">
        <div class="flex flex-row items-center mt-12">
            <h3 class="text-3xl font-semibold mb-5">Bestattungsinstitute</h3>
            <button class="text-xs bg-transparent text-black border border-gray-500 rounded-3xl py-2 px-4 ml-4 -mt-5 font-normal hover:bg-black hover:border-transparent hover:text-white duration-300" (click)="showAddDialog()">Neues Institut</button>
        </div>  
        <p-table [value]="companyList" [tableStyle]="{ 'min-width': '50rem' }" [paginator]="true" [rows]="5" [rowsPerPageOptions]="rowsPerPageOptions" [lazy]="true" (onLazyLoad)="fetchCompanies($event)" [totalRecords]="totalRecords">
            <ng-template pTemplate="header">
                <tr>
                    <th>Unternehmen</th>
                    <th>E-Mail</th>
                    <th>Telefon</th>
                    <th>Webseite</th>
                    <th>Adresse</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-company>
                <tr class="">
                    <td><a [routerLink]="['/bestattungsinstitute', company.companyId]" class="navigation-link hover:underline">{{ company.companyName }}</a></td>
                    <td>{{ company.email }}</td>
                    <td>{{ company.phoneNumber }}</td>
                    <td><a href="https://{{ company.website }}" target="_blank" class="website-link">{{ company.website }}</a></td>
                    <td>{{ company.address }}, {{ company.postalCode }} {{ company.city }}</td>
                    <td class="flex flex-row">
                        <p-button class="text-gray-600 hover:bg-gray-50 rounded-full px-0 mr-2" icon="pi pi-pencil" severity="secondary" [rounded]="true" [text]="true" (onClick)="showEditDialog(company)"></p-button>
                        <p-button class="text-red-600 hover:bg-red-50 rounded-full px-0" icon="pi pi-times" severity="danger" [rounded]="true" [text]="true" (onClick)="deleteCompany(company.companyId)"></p-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <br>
    </div>

    <p-dialog header="Anlegen" [modal]="true" [(visible)]="isAddDialogVisible">
        <app-add-edit-company (companyAdded)="onCompanyAdded()"></app-add-edit-company>     
    </p-dialog>
    <p-dialog header="Bearbeiten" [modal]="true" [(visible)]="isEditDialogVisible">
        <app-add-edit-company (companyEdited)="onCompanyEdited()" [selectedCompany]="selectedCompany"></app-add-edit-company>        
    </p-dialog>
    <p-confirmDialog header="Löschen bestätigen" icon="pi pi-exclamation-triangle"></p-confirmDialog>
    <p-toast></p-toast>
</div>
