<app-navbar></app-navbar>
<div class="px-5 sm:mt-32 mt-28 max-w-md text-center mx-auto">
    <div class="bg-gray-50 p-10 rounded-3xl border border-gray-100">
        <a [routerLink]="['']"><img class="w-28 -mt-5 mx-auto" src="assets/img/logo_small.png"></a>
        <p class="mx-auto text-lg font-semibold">Nur für Bestattungsfachkräfte</p>
        <p class="mb-5 mx-auto text-md text-gray-500 font-light">Bitte Anmeldedaten eingeben</p>
        <form [formGroup]="loginForm" (submit)="login()" class="flex flex-col">
            <input class="mb-5 border pl-4 py-2" id="email" type="text" pInputText formControlName="email" placeholder="E-Mail"/>
            <input class="mb-5 border pl-4 py-2" id="password" type="password" pInputText formControlName="password" placeholder="Passwort"/>
            <p *ngIf="loginFailed" class="text-xs mb-5 -mt-4 text-red-700 border-none"> Falsche E-Mail-Adresse oder falsches Passwort</p>
            <a href="mailto:kontakt@maemona.de?subject=mæmona Passwort zurücksetzen" class="text-gray-400 bg-transparent hover:text-black duration-300 text-sm -mt-3 mb-3 font-light">Passwort vergessen?</a>
            <button type="submit" class="text-white bg-black hover:bg-gray-700 duration-300 px-10 py-3 items-center rounded-3xl w-full mt-2 font-light">Anmelden</button>
        </form>
        <hr class="m-8">
        <div class="flex flex-col">
            <p class="text-gray-500 font-light">Noch nicht registriert? Kontaktieren Sie uns, um den Prozess zu starten.</p>
            <a href="mailto:kontakt@maemona.de?subject=Registrierungsanfrage mæmona" class="text-gray-500 bg-transparent border border-gray-400 px-10 hover:border-black hover:text-black duration-300 py-3 rounded-3xl mt-5">Kontakt</a>
        </div>
    </div>
</div>
