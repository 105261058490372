<app-navbar></app-navbar>
<div class="text-white pb-40 pt-32 text-center px-5 md:pb-36 md:pt-52 md:px-10" id="hero">
  <div class="max-w-4xl mx-auto">
    <h1 lang="de" class="text-5xl text-black font-bold mb-5 hyphens-auto">Willkommen bei <span class="maemona-tag">mæmona</span></h1>
    <p class="mx-auto max-w-4xl text-lg font-light mb-10 text-gray-800">Jemand möchte eine Traueranzeige mit Ihnen teilen. Laden Sie sich einfach die App herunter, um darauf zuzugreifen.</p>
    <div (click)="provideDownloadInfo()" class="flex flex-row mx-auto cursor-pointer w-64 bg-black text-white font-medium rounded-full py-3 px-8 border border-transparent hover:bg-transparent hover:border-black hover:text-black duration-300">
      <svg class="h-8 w-8 mr-2" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="7" y="4" width="10" height="16" rx="1" />  <line x1="11" y1="5" x2="13" y2="5" />  <line x1="12" y1="17" x2="12" y2="17.01" /></svg>
      <button (click)="provideDownloadInfo()" class="mt-1">App herunterladen</button>
    </div>
    <div class="flex flex-row mx-auto cursor-pointer w-64 text-gray-500 font-normal py-3 px-8 hover:text-black duration-300">
        <a href="" class="mt-1 mx-auto">Mehr erfahren</a>
    </div>
  </div>
</div>
<app-footer></app-footer>
<p-dialog header="App herunterladen" [modal]="true" [(visible)]="isDownloadDialogVisible">
  <app-download></app-download>
</p-dialog>


 
  
