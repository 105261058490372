<div *ngIf="this.authService.currentUserSignal()?.role === 'admin'">
    <app-admin-sidebar></app-admin-sidebar>

    <div class="pl-96 pr-24 pt-12 h-screen">
        <h3 class="text-3xl font-semibold mb-10">Dashboard</h3>
        <h2 class="text-5xl font-semibold mb-10 maemona-headline">Willkommen zurück!</h2>
        <div class="flex flex-wrap">
            <div class="max-w-sm bg-gray-100 rounded-3xl p-5 text-center mr-5 mt-5">
                <p class="font-normal text-lg">Neue Trauernanzeige</p>
                <button (click)="isAnnouncementAddDialogVisible = true" class="pb-2 w-20 h-20 mt-7 text-7xl rounded-full bg-black text-white hover:bg-gray-700 duration-300">+</button>
            </div>
            <div class="max-w-sm bg-gray-100 rounded-3xl p-5 text-center mr-5 mt-5">
                <p class="font-normal text-lg text-gray-500">Trauernanzeigen insgesamt</p>
                <p class="font-bold text-5xl mt-4 mb-7 text-black">{{ announcementList.length }}</p>
                <a [routerLink]="['/admin-dashboard']" class="text-xs bg-transparent text-gray-600 border border-gray-500 rounded-3xl py-2 px-4 -mt-5 font-normal hover:bg-black hover:border-transparent hover:text-white duration-300">Übersicht</a>
            </div>
            <div class="max-w-sm bg-gray-100 rounded-3xl p-5 text-center mr-5 mt-5">
                <p class="font-normal text-lg text-gray-500">Zeitungsanzeigen</p>
                <p class="font-bold text-5xl mt-4 mb-7 text-black">{{ filteredAnnouncementList.length }}</p>
                <a [routerLink]="['/admin-traueranzeigen']" class="text-xs bg-transparent text-gray-600 border border-gray-500 rounded-3xl py-2 px-4 -mt-5 font-normal hover:bg-black hover:border-transparent hover:text-white duration-300">Übersicht</a>
                <br class="mb-3">
            </div>
            <div class="max-w-sm bg-gray-100 rounded-3xl p-5 text-center mr-5 mt-5">
                <p class="font-normal text-lg text-gray-500">Bestattungsinstitute</p>
                <p class="font-bold text-5xl mt-4 mb-7 text-black">{{ companyList.length }}</p>
                <a [routerLink]="['/bestattungsinstitute']" class="text-xs bg-transparent text-gray-600 border border-gray-500 rounded-3xl py-2 px-4 -mt-5 font-normal hover:bg-black hover:border-transparent hover:text-white duration-300">Übersicht</a>
                <br class="mb-3">
            </div>
            <div class="max-w-sm bg-gray-100 rounded-3xl p-5 text-center mr-5 mt-5">
                <p class="font-normal text-lg text-gray-500">Mitarbeiter insgesamt</p>
                <p class="font-bold text-5xl mt-4 mb-7 text-black">{{ userList.length }}</p>
                <a [routerLink]="['/admin-dashboard']" class="text-xs bg-transparent text-gray-600 border border-gray-500 rounded-3xl py-2 px-4 -mt-5 font-normal hover:bg-black hover:border-transparent hover:text-white duration-300">Übersicht</a>
                <br class="mb-3">
            </div>
            <div class="max-w-sm min-w-44 bg-gray-100 rounded-3xl p-5 text-center mr-5 mt-5">
                <p class="font-normal text-lg text-gray-500">Mitarbeiter</p>
                <p class="font-bold text-5xl mt-4 mb-7 text-black">{{ filteredUserList.length }}</p>
                <a [routerLink]="['/admin-team']" class="text-xs bg-transparent text-gray-600 border border-gray-500 rounded-3xl py-2 px-4 -mt-5 font-normal hover:bg-black hover:border-transparent hover:text-white duration-300">Übersicht</a>
                <br class="mb-3">
            </div>
        </div>
    </div>

    <p-dialog header="Anzeige anlegen" [modal]="true" [(visible)]="isAnnouncementAddDialogVisible">
        <app-add-edit-announcement (announcementAdded)="onAnnouncementAdded()" [companyId]="companyId"></app-add-edit-announcement>
    </p-dialog>

    <p-toast></p-toast>
</div>
