<app-navbar></app-navbar>
<div class="max-w-5xl mx-auto mt-28">
    <div class="mx-5 flex-1 text-center">
      <h2 class="text-5xl font-bold mb-10 md:mt-14 maemona-headline">Impressum</h2>
    </div>
  </div>
  <svg class="w-full -mb-2" viewBox="0 0 960 30" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><rect x="0" y="0" width="960" height="30" fill="#f3f4f6"></rect><path d="M0 9L17.8 9.2C35.7 9.3 71.3 9.7 106.8 9.5C142.3 9.3 177.7 8.7 213.2 7.8C248.7 7 284.3 6 320 6.7C355.7 7.3 391.3 9.7 426.8 10.5C462.3 11.3 497.7 10.7 533.2 10C568.7 9.3 604.3 8.7 640 9C675.7 9.3 711.3 10.7 746.8 10.3C782.3 10 817.7 8 853.2 7.3C888.7 6.7 924.3 7.3 942.2 7.7L960 8L960 0L942.2 0C924.3 0 888.7 0 853.2 0C817.7 0 782.3 0 746.8 0C711.3 0 675.7 0 640 0C604.3 0 568.7 0 533.2 0C497.7 0 462.3 0 426.8 0C391.3 0 355.7 0 320 0C284.3 0 248.7 0 213.2 0C177.7 0 142.3 0 106.8 0C71.3 0 35.7 0 17.8 0L0 0Z" fill="#ffffff" stroke-linecap="round" stroke-linejoin="miter"></path></svg> 
  <div class="bg-gray-100 pt-10 pb-10 px-5 md:px-10 text-center" id="for-all">
    <p class="text-lg font-bold text-black hyphens-auto">Angaben gemäß § 5 TMG</p>
    <p class="mx-auto max-w-3xl font-light mb-5 text-xl">
      FFWD Florian Werner Digital Ventures<br>
      Marquardsenstr. 4<br>
      91054 Erlangen<br>
      Deutschland<br>
    </p>
    <p class="text-lg font-bold text-black hyphens-auto">Vertreten durch</p>
    <p class="mx-auto max-w-3xl font-light mb-5 text-lg">Florian Werner<br></p>
    <p class="text-lg font-bold text-black hyphens-auto">Kontakt</p>
    <p class="mx-auto max-w-3xl font-light mb-5 text-lg">
      Telefon: +49 176 60883289<br>
      E-Mail: contact[ät]ffwdventures.de<br>
    </p>
    <p class="text-lg font-bold text-black hyphens-auto">Haftungsausschluss</p>
    <p class="mx-auto max-w-3xl font-light mb-5 text-lg">
      Haftung für Inhalte: Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.
    </p>
    <p class="mx-auto max-w-3xl font-light text-lg">
      Haftung für Links: Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
    </p>
    <p class="mx-auto max-w-3xl font-light text-lg">
      Urheberrecht: Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
    </p>
  </div>
  <app-footer></app-footer>
