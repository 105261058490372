<div *ngIf="this.authService.currentUserSignal()?.role === 'admin'">
<section class="listing-apply">
    <form [formGroup]="userForm" (submit)="addOrEditUser()" class="flex flex-col">
        <input class="border p-2 mb-3 mt-1" id="first-name" type="text" pInputText formControlName="firstName" placeholder="Vorname"/>
        <input class="border p-2 mb-3 mt-1" id="last-name" type="text" pInputText formControlName="lastName" placeholder="Nachname"/>
        <input class="border p-2 mb-3 mt-1" id="email" type="text" pInputText formControlName="email" placeholder="E-Mail"/>
        <input class="border p-2 mb-3 mt-1" id="password" type="password" pInputText formControlName="password" placeholder="Passwort"/>
        <div class="verified-container mt-1">
            <p>Verifiziert</p>
            <p-inputSwitch id="verified" formControlName="verified"></p-inputSwitch>
        </div>
        <div class="mx-auto max-w-32">
            <button class="text-white bg-black px-5 py-3 mt-5 items-center rounded-3xl" type="submit">Speichern</button>
        </div>
    </form>
</section>
</div>
