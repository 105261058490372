<div *ngIf="this.authService.currentUserSignal()?.role === 'admin'">
    <app-admin-sidebar></app-admin-sidebar>

    <div class="ml-96 mr-24 m-12">
        <div class="flex flex-row items-center mt-12">
            <h3 class="text-3xl font-semibold mb-5">Teammitglieder</h3>
            <button class="text-xs bg-transparent text-black border border-gray-500 rounded-3xl py-2 px-4 ml-4 -mt-5 font-normal hover:bg-black hover:border-transparent hover:text-white duration-300" (click)="showAddEditDialog(true,companyId)">Neues Mitglied</button>
        </div>  
        <p-table [value]="userList" [tableStyle]="{ 'min-width': '50rem' }" [paginator]="true" [rows]="5" [rowsPerPageOptions]="rowsPerPageOptions" [lazy]="true" (onLazyLoad)="fetchUsers($event)" [totalRecords]="totalRecords">
            <ng-template pTemplate="header">
                <tr>
                    <th>Name</th>
                    <th>E-Mail</th>
                    <th>Angelet am</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-user>
                <tr>
                    <td>{{ user.firstName }} {{ user.lastName }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.createdAt | customDateFormat: 'dateTime' }}</td>
                    <td class="flex flex-row">
                        <p-button class="text-gray-600 hover:bg-gray-50 rounded-full px-0 mr-2" icon="pi pi-pencil" severity="secondary" [rounded]="true"  [text]="true" (onClick)="showAddEditDialog(false, user)"></p-button>
                        <p-button class="text-red-600 hover:bg-red-50 rounded-full px-0" icon="pi pi-times" severity="danger" [rounded]="true"  [text]="true" (onClick)="deleteUser(user.userId)"></p-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <br>
    </div>

    <p-confirmDialog header="Löschen bestätigen" icon="pi pi-exclamation-triangle"></p-confirmDialog>
    <p-dialog header="Person bearbeiten" [modal]="true" [(visible)]="isEditDialogVisible">
        <app-add-edit-user (userEdited)="onUserEdited()" [selectedUser]="selectedUser"></app-add-edit-user>
    </p-dialog>
    <p-dialog header="Person anlegen" [modal]="true" [(visible)]="isAddDialogVisible">
        <app-add-edit-user (userAdded)="onUserAdded()" [companyId]="companyId"></app-add-edit-user>
    </p-dialog>
</div>
