import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { NgIf } from '@angular/common'
import { CurrentRouteService } from '../../../services/current-route.service';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    RouterModule,
    ButtonModule,
    NgIf
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css'
})
export class NavbarComponent {
  constructor(private currentRouteService: CurrentRouteService, ) {}

  title = 'mæmona';
  authService: AuthService = inject(AuthService);

  isActive(targetPath: string) {
    return this.currentRouteService.checkPath(targetPath)
  }

  onActivate() {
    window.scroll(0,0);
  }

}
