import { Component, inject } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { AuthService } from '../../../../services/auth.service';
import { LoginResponse } from '../../../../interfaces/login-response';
import { FooterComponent } from '../../../website/footer/footer.component';
import { NavbarComponent } from '../../../website/navbar/navbar.component';
import { Router } from '@angular/router';
import { RouterModule } from '@angular/router';
import { NgIf } from '@angular/common';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ButtonModule,
    ReactiveFormsModule,
    InputTextModule,
    FooterComponent,
    NavbarComponent,
    RouterModule,
    NavbarComponent,
    NgIf
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent {
  constructor(private router: Router) {}

  authService: AuthService = inject(AuthService)
  loginFailed: boolean = false;

  loginForm = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl(''),
  });

  async ngOnInit(): Promise<void> {
    await this.authService.waitForCurrentUserSignal();
    if (this.authService.currentUserSignal()?.role === 'undertaker') {
      this.router.navigate(['/dashboard']);
    } else if (this.authService.currentUserSignal()?.role === 'admin') {
      this.router.navigate(['/admin-dashboard']);
    }
  }

  login(): void {
    fetch(environment.apiUrl + "users/login", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: this.loginForm.value.email,
        password: this.loginForm.value.password
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Anmeldedaten sind ungültig');
      }
      return response.json();
    })
    .then((response: LoginResponse) => {
      localStorage.setItem('userId', response.user.userId);
      localStorage.setItem('subUserId', response.key);
      this.authService.currentUserSignal.set(response.user);
      if (this.authService.currentUserSignal()?.role === 'admin') {
        this.router.navigate(['/admin-dashboard']);
      } else if (this.authService.currentUserSignal()?.role === 'undertaker') {
        this.router.navigate(['/dashboard']);
      }
      this.loginFailed = false;
    })
    .catch(error => {
      console.error('Fehler beim Anmelden:', error);
      this.loginFailed = true;
    });
  }
  
}
