import { bootstrapApplication, provideProtractorTestingSupport } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { importProvidersFrom } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, provideRouter } from '@angular/router';
import routeConfig from './app/routes';
import routes from './app/routes';

bootstrapApplication(AppComponent,{
  providers:[
    importProvidersFrom([
      BrowserAnimationsModule,
      RouterModule.forRoot(routes, { useHash: true })
    ]),
    provideProtractorTestingSupport(),
    provideRouter(routeConfig),
  ]
}); 
