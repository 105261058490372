import { Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { CurrentRouteService } from '../../../../services/current-route.service';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    RouterModule,
    DialogModule,
    ChangePasswordComponent
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.css'
})
export class SidebarComponent {

  constructor(private router: Router, private messageService: MessageService, private currentRouteService: CurrentRouteService) {}

  isPasswordChangeDialogVisible = false;

  authService: AuthService = inject(AuthService);

  isActive(targetPath: string) {
    return this.currentRouteService.checkPath(targetPath)
  }

  onPasswordChanged() {
    this.isPasswordChangeDialogVisible = false;
    this.messageService.add({ severity: 'success', summary: 'Ändern erfolgreich', detail: "Passwort wurde geändert" });
  }

  logout() {
    localStorage.setItem("userId", '');
    localStorage.setItem("subUserId", '');
    this.authService.currentUserSignal.set(null);
    this.router.navigate(['/login']);
  }

}
