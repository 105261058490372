<app-navbar></app-navbar>
<div class="text-white pb-40 pt-32 text-center px-5 md:pb-36 md:pt-52 md:px-10" id="hero">
  <div class="max-w-4xl mx-auto">
    <h1 lang="de" class="text-5xl text-black font-bold mb-5 hyphens-auto">Moderne Traueranzeigen für alle</h1>
    <p class="mx-auto max-w-4xl text-lg font-light mb-10 text-gray-800">Persönliche Erinnerungen, veröffentlicht <b class="font-semibold">von geprüften Bestattungsinstituten</b>, die sofort unkompliziert mit Familie und Bekannten geteilt werden können. <b class="font-semibold">Ohne Benutzerkonto</b> und ohne die starre Struktur von Zeitungsinseraten.</p>
    <div (click)="provideDownloadInfo()" class="flex flex-row mx-auto cursor-pointer w-64 bg-black text-white font-medium rounded-full py-3 px-8 border border-transparent hover:bg-transparent hover:border-black hover:text-black duration-300">
      <svg class="h-8 w-8 mr-2" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="7" y="4" width="10" height="16" rx="1" />  <line x1="11" y1="5" x2="13" y2="5" />  <line x1="12" y1="17" x2="12" y2="17.01" /></svg>
      <button (click)="provideDownloadInfo()" class="mt-1">App herunterladen</button>
    </div>
  </div>
</div>
<svg class="w-full -mb-2" id="visual" viewBox="0 0 960 30" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><rect x="0" y="0" width="960" height="30" fill="#f3f4f6"></rect><path d="M0 9L17.8 9.2C35.7 9.3 71.3 9.7 106.8 9.5C142.3 9.3 177.7 8.7 213.2 7.8C248.7 7 284.3 6 320 6.7C355.7 7.3 391.3 9.7 426.8 10.5C462.3 11.3 497.7 10.7 533.2 10C568.7 9.3 604.3 8.7 640 9C675.7 9.3 711.3 10.7 746.8 10.3C782.3 10 817.7 8 853.2 7.3C888.7 6.7 924.3 7.3 942.2 7.7L960 8L960 0L942.2 0C924.3 0 888.7 0 853.2 0C817.7 0 782.3 0 746.8 0C711.3 0 675.7 0 640 0C604.3 0 568.7 0 533.2 0C497.7 0 462.3 0 426.8 0C391.3 0 355.7 0 320 0C284.3 0 248.7 0 213.2 0C177.7 0 142.3 0 106.8 0C71.3 0 35.7 0 17.8 0L0 0Z" fill="#ffffff" stroke-linecap="round" stroke-linejoin="miter"></path></svg> 
<div class="bg-gray-100 -pt-40 pb-14" id="function">
  <div class="max-w-5xl mx-auto flex flex-col-reverse md:flex-row">
    <div class="mx-5 flex-1">
      <h2 class="text-3xl font-bold mb-10 md:mt-14 maemona-headline">So funktioniert's</h2>
      <ol class="list-decimal font-light ml-6 text-xl mb-10">
        <li>Im Trauerfall Bestattungsfachkraft auf <span class="maemona-tag">mæmona</span> ansprechen</li>
        <li>Anzeige gemeinsam mit Bestattungsfachkraft aufgeben</li>
        <li>App herunterladen und Link zur Anzeige teilen</li>
        <li>In Zukunft über Trauerfälle informiert bleiben</li>
      </ol>
    </div>
    <div class="html5video">
      <video src="assets/video/video.mp4" muted poster="assets/img/thumbnail.jpg" controls preload="metadata" playsInline class="mx-auto -mt-16 mb-10 shadow-lg rounded-3xl md:mb-0 md:-mt-28 max-w-96 w-11/12 object-cover"></video>
    </div>
  </div>
</div>   
<svg class="w-full" id="visual" viewBox="0 0 960 30" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><rect x="0" y="0" width="960" height="30" fill="#f3f4f6"></rect><path d="M0 21L17.8 20.8C35.7 20.7 71.3 20.3 106.8 20.5C142.3 20.7 177.7 21.3 213.2 22C248.7 22.7 284.3 23.3 320 22.7C355.7 22 391.3 20 426.8 19.3C462.3 18.7 497.7 19.3 533.2 19.8C568.7 20.3 604.3 20.7 640 20.3C675.7 20 711.3 19 746.8 19.3C782.3 19.7 817.7 21.3 853.2 21.8C888.7 22.3 924.3 21.7 942.2 21.3L960 21L960 31L942.2 31C924.3 31 888.7 31 853.2 31C817.7 31 782.3 31 746.8 31C711.3 31 675.7 31 640 31C604.3 31 568.7 31 533.2 31C497.7 31 462.3 31 426.8 31C391.3 31 355.7 31 320 31C284.3 31 248.7 31 213.2 31C177.7 31 142.3 31 106.8 31C71.3 31 35.7 31 17.8 31L0 31Z" fill="#ffffff" stroke-linecap="round" stroke-linejoin="miter"></path></svg>
<div class="max-w-6xl mx-auto flex flex-col-reverse md:flex-row py-10 px-10" id="for-all">
  <div id="container" class="mx-auto md:mr-10 mt-10 md:mt-0">
    <img id="frame" src="assets/img/frame.png" alt="App frame">
    <div id="mockup">
      <img id="screen" src="assets/img/screen.png" alt="App content">
    </div>
  </div>
  <div class="md:mt-20">
    <h2 class="text-3xl font-bold text-black mb-5 hyphens-auto maemona-headline">Traueranzeigen können mehr</h2>
    <p class="mx-auto max-w-3xl font-light mb-10 text-xl">Der Umgang mit dem Verlust von Menschen ist herausfordernd, doch Anteilnahme und gemeinschaftliche Unterstützung können beim Trauern helfen. Mit <span class="maemona-tag">mæmona</span> tragen wir dazu bei, diesen Prozess zu erleichtern, indem wir Traueranzeigen neu denken und für alle zugänglich machen.</p>
    <p class="mx-auto max-w-3xl font-light -mb-5 text-xl">Ohne die Limitierung durch das Zeitungsformat, ergeben sich viele Möglichkeiten, Anzeigen mit schönen Erinnerungen und sinnvollen Informationen anzureichern. Zusätzliche Medien, Navigationsdaten und Kalendertermine sind nur der Anfang.</p>
  </div>
  <!-- <img src="assets/img/media.png" class="mx-auto mt-16 md:max-w-2xl max-w-11/12"> -->
</div>
<svg class="w-full -mb-2" id="visual" viewBox="0 0 960 30" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><rect x="0" y="0" width="960" height="30" fill="#f3f4f6"></rect><path d="M0 9L17.8 9.2C35.7 9.3 71.3 9.7 106.8 9.5C142.3 9.3 177.7 8.7 213.2 7.8C248.7 7 284.3 6 320 6.7C355.7 7.3 391.3 9.7 426.8 10.5C462.3 11.3 497.7 10.7 533.2 10C568.7 9.3 604.3 8.7 640 9C675.7 9.3 711.3 10.7 746.8 10.3C782.3 10 817.7 8 853.2 7.3C888.7 6.7 924.3 7.3 942.2 7.7L960 8L960 0L942.2 0C924.3 0 888.7 0 853.2 0C817.7 0 782.3 0 746.8 0C711.3 0 675.7 0 640 0C604.3 0 568.7 0 533.2 0C497.7 0 462.3 0 426.8 0C391.3 0 355.7 0 320 0C284.3 0 248.7 0 213.2 0C177.7 0 142.3 0 106.8 0C71.3 0 35.7 0 17.8 0L0 0Z" fill="#ffffff" stroke-linecap="round" stroke-linejoin="miter"></path></svg> 
<div class="bg-gray-100 py-20 px-5 md:px-10 text-center">
  <img class="mx-auto w-14 mb-5" src="assets/img/badge.png">
  <h2 class="text-3xl font-bold text-black mb-5 hyphens-auto maemona-headline">Anzeigen von geprüften Bestattungsinstituten</h2>
  <p class="mx-auto max-w-3xl font-light mb-10 text-xl">Traueranzeigen können bei uns nur durch geprüfte Bestattungshäuser angelegt werden. So stellen wir sicher, dass ausschließlich echte Trauerfälle veröffentlicht werden.</p>
  <div (click)="provideDownloadInfo()" class="cursor-pointer flex flex-row mx-auto w-64 bg-black text-white font-normal rounded-full py-3 px-8 border border-transparent hover:bg-transparent hover:border-black hover:text-black duration-300">
    <svg class="h-8 w-8 mr-2" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="7" y="4" width="10" height="16" rx="1" />  <line x1="11" y1="5" x2="13" y2="5" />  <line x1="12" y1="17" x2="12" y2="17.01" /></svg>
    <button (click)="provideDownloadInfo()" class="mt-1">App herunterladen</button>
  </div>
</div>
<!-- <svg class="w-full" id="visual" viewBox="0 0 960 30" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><rect x="0" y="0" width="960" height="30" fill="#f3f4f6"></rect><path d="M0 21L17.8 20.8C35.7 20.7 71.3 20.3 106.8 20.5C142.3 20.7 177.7 21.3 213.2 22C248.7 22.7 284.3 23.3 320 22.7C355.7 22 391.3 20 426.8 19.3C462.3 18.7 497.7 19.3 533.2 19.8C568.7 20.3 604.3 20.7 640 20.3C675.7 20 711.3 19 746.8 19.3C782.3 19.7 817.7 21.3 853.2 21.8C888.7 22.3 924.3 21.7 942.2 21.3L960 21L960 31L942.2 31C924.3 31 888.7 31 853.2 31C817.7 31 782.3 31 746.8 31C711.3 31 675.7 31 640 31C604.3 31 568.7 31 533.2 31C497.7 31 462.3 31 426.8 31C391.3 31 355.7 31 320 31C284.3 31 248.7 31 213.2 31C177.7 31 142.3 31 106.8 31C71.3 31 35.7 31 17.8 31L0 31Z" fill="#ffffff" stroke-linecap="round" stroke-linejoin="miter"></path></svg>
<div class="pt-20 pb-24 px-5 md:px-10 text-center" id="prices">
  <h2 class="text-5xl font-bold text-black mb-6 hyphens-auto maemona-headline">Wählen Sie ein passendes Paket</h2>
  <p class="mx-auto max-w-4xl font-light mt-5 text-xl mb-12">Alle Pakete beinhalten eine Trauerzeige, die umgehend nach Erstellung durch die Bestattungsfachkraft geteilt werden können und für alle kostenlos und ohne Benutzerkonto zugänglich sind. Der Preis fällt einmalig bei Veröffentlichung an.</p>
  <div class="max-w-3xl flex flex-col md:flex-row mx-auto -mb-10">
    <div class="bg-gray-50 mx-auto w-72 md:w-80 h-96 rounded-3xl md:mr-4 mb-10 border border-gray-100">
      <p class="mt-7 text-xl font-bold text-gray-700">Basis</p>
      <p class="mt-3 text-3xl font-black -mb-5 text-gray-800">€ 19,99</p>
      <p class="mt-5 text-md text-gray-500">inkl. MwSt.</p>
      <p class="text-left mt-5 text-md text-gray-500 font-semibold mx-5">Beinhaltet:</p>
      <p class="text-left mt-3 text-md text-gray-600 mx-5 flex items-center">
        <svg class="h-4 w-4 text-gray-500 mr-2"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>            
        Name
      </p>
      <p class="text-left mt-2 text-md text-gray-600 mx-5 flex items-center">
        <svg class="h-4 w-4 text-gray-500 mr-2"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>            
        Geburtstag
      </p>
      <p class="text-left mt-2 text-md text-gray-600 mx-5 flex items-center">
        <svg class="h-4 w-4 text-gray-500 mr-2"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>            
        Todestag
      </p>
      <p class="text-left mt-2 text-md text-gray-600 mx-5 flex items-center">
        <svg class="h-4 w-4 text-gray-500 mr-2"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>            
        Anzeigenfoto
      </p>
      <p class="text-left mt-2 text-md text-gray-600 mx-5 flex items-center">
        <svg class="h-4 w-4 text-gray-500 mr-2"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>            
        Anzeigentext
      </p>
    </div>
    <div class="bg-gray-50 mx-auto w-72 md:w-96 h-96 rounded-3xl mb-10 md:-mt-3 md:shadow-lg border border-gray-200">
      <p class="mt-7 text-2xl font-bold">Standard</p>
      <p class="mt-3 text-4xl font-black -mb-5">€ 24,99</p>
      <p class="mt-5 text-lg text-gray-400">inkl. MwSt.</p>
      <p class="text-left mt-5 text-md font-semibold mx-5">Beinhaltet alles aus Paket "Basis" plus:</p>
      <p class="text-left mt-3 text-md text-gray-500 mx-5 flex items-center">
        <svg class="h-4 w-4 text-gray-400 mr-2"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>            
        Infos zur Bestattung
      </p>
      <p class="text-left mt-2 text-md text-gray-500 mx-5 flex items-center">
        <svg class="h-4 w-4 text-gray-400 mr-2"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>            
        Bis zu fünf Bilder
      </p>
      <p class="mx-14 mt-8 text-sm py-2 bg-transparent border border-gray-300 rounded-3xl text-gray-500">Empfehlung</p>
    </div>
    <div class="bg-gray-50 mx-auto w-72 md:w-80 h-96 rounded-3xl md:ml-4 mb-10 border border-gray-100">
      <p class="mt-7 text-xl font-bold text-gray-700">Erweitert</p>
      <p class="mt-3 text-3xl font-black -mb-5 text-gray-800">€ 29,99</p>
      <p class="mt-5 text-md text-gray-500">inkl. MwSt.</p>
      <p class="text-left mt-5 text-md text-gray-500 font-semibold mx-5">Beinhaltet alles aus Paket "Standard" plus:</p>
      <p class="text-left mt-3 text-md text-gray-600 mx-5 flex items-center">
        <svg class="h-4 w-4 text-gray-500 mr-2"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>            
        Bis zu zehn Bilder
      </p>
      <p class="text-left mt-2 text-md text-gray-600 mx-5 flex items-center">
        <svg class="h-4 w-4 text-gray-500 mr-2"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>            
        Ein Video
      </p>
    </div>
  </div>
</div> -->
<app-footer></app-footer>
<p-dialog header="App herunterladen" [modal]="true" [(visible)]="isDownloadDialogVisible">
  <app-download></app-download>
</p-dialog>


 
  
