import { Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { ImageModule } from 'primeng/image';
import { CustomDateFormatPipe } from '../../../../pipes/custom-date-format.pipe';
import { ButtonModule } from 'primeng/button';
import { User } from '../../../../interfaces/user';
import { Announcement } from '../../../../interfaces/announcement';
import { AnnouncementsService } from '../../../../services/announcements.service';
import { UsersService } from '../../../../services/users.service';
import { NgIf } from '@angular/common';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { AddEditAnnouncementComponent } from '../../mutual/add-edit-announcement/add-edit-announcement.component';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { environment } from '../../../../../environments/environment';
import { AdminSidebarComponent } from '../admin-sidebar/admin-sidebar.component';

@Component({
  selector: 'app-admin-announcements',
  standalone: true,
  imports: [
    RouterModule,
    TableModule,
    ImageModule,
    CustomDateFormatPipe,
    ButtonModule,
    NgIf,
    DialogModule,
    AddEditAnnouncementComponent,
    ToastModule,
    ConfirmDialogModule,
    AdminSidebarComponent
  ],
  providers: [ConfirmationService, MessageService],
  templateUrl: './admin-announcements.component.html',
  styleUrl: './admin-announcements.component.css'
})
export class AdminAnnouncementsComponent {
  announcementsService: AnnouncementsService =inject(AnnouncementsService)
  announcementList: Announcement[] = []
  url = environment.apiUrl + "announcementfilesbucket/";
  authService: AuthService = inject(AuthService)
  usersService: UsersService = inject(UsersService)
  userList: User[] = []
  companyId = ""
  isAnnouncementAddDialogVisible = false;
  isAnnouncementEditDialogVisible = false;
  selectedAnnouncement: any = null;
  totalRecords!: number;
  rowsPerPageOptions = [5,10,20];

  constructor(private router: Router, private confirmationService: ConfirmationService, private messageService: MessageService) {}

  async ngOnInit(): Promise<void> {
    await this.authService.waitForCurrentUserSignal();
    this.companyId = this.authService.currentUserSignal()?.companyId ?? "";
    this.fetchUsers().then(() => {
      this.fetchAnnouncements();
    });
    if (this.authService.currentUserSignal() == null) {
      this.router.navigate(['/login']);
    } else if (this.authService.currentUserSignal()?.role != 'admin') {
      this.router.navigate(['/dashboard']);
    }
  }

  fetchUsers() {
    return new Promise<void>((resolve) => {
        if (this.companyId != "") {
            this.usersService.getAllUsersForCompany(this.companyId).then((userData: any) => {
                this.userList = userData.items;
                resolve(); // Signalisiert, dass fetchUsers abgeschlossen ist
            });
        } else {
            resolve(); // Signalisiert, dass fetchUsers abgeschlossen ist, wenn companyId nicht gesetzt ist
        }
    });
  }

  fetchAnnouncements($event?: TableLazyLoadEvent) {
    if (this.companyId != "" ) {
      this.announcementsService.getAllAnnouncementsForCompany(this.companyId, $event?.first || 0, $event?.rows || 5).then((announcementData: any) => {
        this.totalRecords = announcementData.totalItems;
        this.announcementList = announcementData.items.map((announcement: Announcement) => {
          const user = this.userList.find(user => user.userId === announcement.userId);
          return {
            ...announcement,
            userName: user ? user.firstName + ' ' + user.lastName : 'Unbekannter Benutzer'
          };
        });
      })
    }
  }

  showAnnouncementAddDialog(companyId: any) {
    this.isAnnouncementAddDialogVisible = true;
    this.companyId = companyId;
  }

  onAnnouncementAdded() {
    this.fetchAnnouncements();
    this.isAnnouncementAddDialogVisible = false;
    this.messageService.add({ severity: 'success', summary: 'Anlegen erfolgreich', detail: "Traueranzeige wurde angelegt" });
  }

  showAnnouncementEditDialog(announcement: Announcement, companyId: any) {
    this.isAnnouncementEditDialogVisible = true;
    this.selectedAnnouncement = announcement;
    this.companyId = companyId;
  }

  onAnnouncementEdited() {
    this.fetchAnnouncements();
    this.isAnnouncementEditDialogVisible = false;
    this.messageService.add({ severity: 'success', summary: 'Bearbeiten erfolgreich', detail: "Traueranzeige wurde bearbeitet" });
  }

  deleteAnnouncement(id: string) {
    this.announcementsService.getAnnouncementById(id).then((announcement: any) => {
      if (announcement) {
        announcement = announcement[0];
        this.confirmationService.confirm({
            message: "Traueranzeige für "+announcement.firstName+" "+announcement.lastName+" wirkllich löschen?",
            acceptLabel: "Ja",
            rejectLabel: "Nein",
            acceptButtonStyleClass: "p-button-danger",
            acceptIcon:"none",
            rejectIcon:"none",
            accept: () => {
              this.announcementsService.deleteAnnouncement(id).then(() => {
                this.fetchAnnouncements();
                this.messageService.add({ severity: 'success', summary: 'Löschen bestätigt', detail: "Traueranzeige wurde gelöscht" });
              })
              .catch((error) => {
                console.log(error);
                this.messageService.add({ severity: 'error', summary: 'Fehler beim Löschen', detail: 'Das Löschen der Traueranzeige von ' + announcement.firstName + ' ' + announcement.lastName + ' ist fehlgeschlagen' });
              });
            }
        });
      }
    });
  }
}
