import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { NgIf } from '@angular/common';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AddEditUserComponent } from '../../admin/add-edit-user/add-edit-user.component';
import { UsersService } from '../../../../services/users.service';
import { User } from '../../../../interfaces/user';
import { CustomDateFormatPipe } from '../../../../pipes/custom-date-format.pipe';
import { MessageService } from 'primeng/api';
import { SidebarComponent } from '../sidebar/sidebar.component';

@Component({
  selector: 'app-undertaker-team',
  standalone: true,
  imports: [
    RouterModule,
    NgIf,
    TableModule,
    ToastModule,
    DialogModule,
    ConfirmDialogModule,
    AddEditUserComponent,
    CustomDateFormatPipe,
    SidebarComponent
  ],
  providers: [MessageService],
  templateUrl: './undertaker-team.component.html',
  styleUrl: './undertaker-team.component.css'
})
export class UndertakerTeamComponent {
  authService: AuthService = inject(AuthService)
  usersService: UsersService = inject(UsersService)
  userList: User[] = []
  companyId = ""
  totalRecords!: number;
  rowsPerPageOptions = [5,10,20];

  constructor(private router: Router) {}

  async ngOnInit(): Promise<void> {
    await this.authService.waitForCurrentUserSignal();
    this.companyId = this.authService.currentUserSignal()?.companyId ?? "";
    this.fetchUsers();
    if (this.authService.currentUserSignal() == null) {
      this.router.navigate(['/login']);
    } else if (this.authService.currentUserSignal()?.role != 'undertaker') {
      this.router.navigate(['/admin-team']);
    }
  }

  fetchUsers($event?: TableLazyLoadEvent) {
    if (this.companyId != "" ) {
      this.usersService.getAllUsersForCompany(this.companyId, $event?.first || 0, $event?.rows || 5).then((userData: any) => {
        this.totalRecords = userData.totalItems;
        this.userList = userData.items;
      });
    }
  }
}
