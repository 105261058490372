<app-navbar></app-navbar>
<div class="max-w-5xl mx-auto mt-28">
    <div class="mx-5 flex-1 text-center">
      <img class="mx-auto w-16 -mb-8" src="assets/img/support.png">
      <h2 class="text-5xl font-bold mt-14 mb-5 hyphens-auto maemona-headline">Hilfe</h2>
      <p class="mx-auto max-w-3xl font-light mb-10 text-lg">Bei <span class="maemona-tag">mæmona</span> ist uns Ihre Zufriedenheit wichtig. Unser Team steht Ihnen zur Verfügung, um Ihnen bei allen Fragen, Anliegen oder Problemen behilflich zu sein. Bitte zögern Sie nicht, uns zu kontaktieren, wir sind gerne für Sie da.</p>
    </div>
  </div>
  <svg class="w-full" viewBox="0 0 960 30" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><rect x="0" y="0" width="960" height="30" fill="#f3f4f6"></rect><path d="M0 9L17.8 9.2C35.7 9.3 71.3 9.7 106.8 9.5C142.3 9.3 177.7 8.7 213.2 7.8C248.7 7 284.3 6 320 6.7C355.7 7.3 391.3 9.7 426.8 10.5C462.3 11.3 497.7 10.7 533.2 10C568.7 9.3 604.3 8.7 640 9C675.7 9.3 711.3 10.7 746.8 10.3C782.3 10 817.7 8 853.2 7.3C888.7 6.7 924.3 7.3 942.2 7.7L960 8L960 0L942.2 0C924.3 0 888.7 0 853.2 0C817.7 0 782.3 0 746.8 0C711.3 0 675.7 0 640 0C604.3 0 568.7 0 533.2 0C497.7 0 462.3 0 426.8 0C391.3 0 355.7 0 320 0C284.3 0 248.7 0 213.2 0C177.7 0 142.3 0 106.8 0C71.3 0 35.7 0 17.8 0L0 0Z" fill="#ffffff" stroke-linecap="round" stroke-linejoin="miter"></path></svg> 
  <div class="bg-gray-100 pt-10 pb-10 px-5 md:px-10 text-center">
    <h2 class="text-3xl font-bold text-black mb-5 hyphens-auto">Kontaktieren Sie uns</h2>
    <p class="mx-auto max-w-3xl font-light text-lg">Senden Sie uns eine E-Mail an kontakt&#64;maemona.de. Wir bemühen uns, alle Anfragen innerhalb von 24 Stunden zu beantworten.</p>
  </div>
  <svg class="w-full" id="user-faq" viewBox="0 0 960 30" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><rect x="0" y="0" width="960" height="30" fill="#f3f4f6"></rect><path d="M0 21L17.8 20.8C35.7 20.7 71.3 20.3 106.8 20.5C142.3 20.7 177.7 21.3 213.2 22C248.7 22.7 284.3 23.3 320 22.7C355.7 22 391.3 20 426.8 19.3C462.3 18.7 497.7 19.3 533.2 19.8C568.7 20.3 604.3 20.7 640 20.3C675.7 20 711.3 19 746.8 19.3C782.3 19.7 817.7 21.3 853.2 21.8C888.7 22.3 924.3 21.7 942.2 21.3L960 21L960 31L942.2 31C924.3 31 888.7 31 853.2 31C817.7 31 782.3 31 746.8 31C711.3 31 675.7 31 640 31C604.3 31 568.7 31 533.2 31C497.7 31 462.3 31 426.8 31C391.3 31 355.7 31 320 31C284.3 31 248.7 31 213.2 31C177.7 31 142.3 31 106.8 31C71.3 31 35.7 31 17.8 31L0 31Z" fill="#ffffff" stroke-linecap="round" stroke-linejoin="miter"></path></svg>
  <div class="pt-10 pb-10 px-5 md:px-10 text-center">
    <h2 class="text-3xl font-bold text-black mb-5 hyphens-auto">Häufig gestellte Fragen von Nutzern</h2>
    <p class="mx-auto max-w-3xl font-bold text-lg">Kann ich als Privatperson eine Traueranzeige erstellen?</p>
    <p class="mx-auto font-light max-w-3xl mb-5 text-lg">Nein, Traueranzeigen können nur von geprüften Bestattungsfachkräften erstellt werden. Wenn Sie eine Anzeige erstellen möchten, wenden Sie sich bitte an ein Bestattungsinstitut.</p>
    <p class="mx-auto max-w-3xl font-bold text-lg">Kann ich als Angehöriger die Anzeige bearbeiten oder löschen?</p>
    <p class="mx-auto font-light max-w-3xl mb-5 text-lg">Nein, als Angehöriger haben Sie nicht die Möglichkeit, eine Anzeige direkt zu bearbeiten oder zu löschen. Bitte wenden Sie sich an die Bestattungsfachkraft, die die Anzeige erstellt hat, um Änderungen vorzunehmen.</p>
    <p class="mx-auto max-w-3xl font-bold text-lg">Wie kann ich eine Anzeige finden?</p>
    <p class="mx-auto font-light max-w-3xl mb-5 text-lg">Sie können Traueranzeigen in unserer App durchsuchen, indem Sie den Namen der verstorbenen Person in die Suchleiste eingeben oder entsprechende Filter definieren.</p>
    <p class="mx-auto max-w-3xl font-bold text-lg">Wie erfahre ich von neuen Traueranzeigen?</p>
    <p class="mx-auto font-light max-w-3xl mb-5 text-lg">Nutzen Sie unsere App, um über Trauerfälle informiert zu bleiben. Nutzen Sie entsprechende Filter, um eine Region oder auch eine spezielle Gruppe im Blick zu behalten.</p>
  </div>
  <svg class="w-full -mb-2" id="undertaker-faq" viewBox="0 0 960 30" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><rect x="0" y="0" width="960" height="30" fill="#f3f4f6"></rect><path d="M0 9L17.8 9.2C35.7 9.3 71.3 9.7 106.8 9.5C142.3 9.3 177.7 8.7 213.2 7.8C248.7 7 284.3 6 320 6.7C355.7 7.3 391.3 9.7 426.8 10.5C462.3 11.3 497.7 10.7 533.2 10C568.7 9.3 604.3 8.7 640 9C675.7 9.3 711.3 10.7 746.8 10.3C782.3 10 817.7 8 853.2 7.3C888.7 6.7 924.3 7.3 942.2 7.7L960 8L960 0L942.2 0C924.3 0 888.7 0 853.2 0C817.7 0 782.3 0 746.8 0C711.3 0 675.7 0 640 0C604.3 0 568.7 0 533.2 0C497.7 0 462.3 0 426.8 0C391.3 0 355.7 0 320 0C284.3 0 248.7 0 213.2 0C177.7 0 142.3 0 106.8 0C71.3 0 35.7 0 17.8 0L0 0Z" fill="#ffffff" stroke-linecap="round" stroke-linejoin="miter"></path></svg> 
  <div class="bg-gray-100 pt-10 pb-10 px-5 md:px-10 text-center">
    <h2 class="text-3xl font-bold text-black mb-5 hyphens-auto">Häufig gestellte Fragen von Bestattungsfachkräften</h2>
    <p class="mx-auto max-w-3xl font-bold text-lg">Wie erstelle ich eine neue Traueranzeige?</p>
    <p class="mx-auto font-light max-w-3xl mb-5 text-lg">Als registrierte Bestattungsfachkraft können Sie sich in Ihr Konto einloggen und von dort aus eine neue Traueranzeige erstellen. Folgen Sie den Anweisungen auf dem Bildschirm, um die Anzeige mit den erforderlichen Informationen zu erstellen.</p>
    <p class="mx-auto max-w-3xl font-bold text-lg">Welche Informationen benötige ich, um eine Anzeige zu erstellen?</p>
    <p class="mx-auto font-light max-w-3xl mb-5 text-lg">Sie benötigen grundlegende Informationen über den Verstorbenen, einschließlich seines Namens, seines Geburtsdatums, seines Sterbedatums und anderer relevanter Details. Zusätzlich können Sie Medien hinzufügen.</p>
    <p class="mx-auto max-w-3xl font-bold text-lg">Wie lange dauert es, bis eine Anzeige veröffentlicht wird?</p>
    <p class="mx-auto font-light max-w-3xl mb-5 text-lg">Anzeigen werden sofort veröffentlicht, sobald Sie von Ihnen erstellt wurden und stehen somit direkt für Ihre Kunden und andere Nutzer bereit.</p>
    <p class="mx-auto max-w-3xl font-bold text-lg">Kann ich eine Anzeige nach der Veröffentlichung bearbeiten?</p>
    <p class="mx-auto font-light max-w-3xl mb-5 text-lg">Ja, Sie können eine Anzeige nach der Veröffentlichung bearbeiten, um Änderungen vorzunehmen oder zusätzliche Informationen hinzuzufügen. Melden Sie sich einfach in Ihrem Konto an und wählen Sie die entsprechende Anzeige aus.</p>
    <p class="mx-auto max-w-3xl font-bold text-lg">Was kann ich tun, wenn ich technische Probleme beim Erstellen oder Bearbeiten einer Anzeige habe?</p>
    <p class="mx-auto font-light max-w-3xl mb-5 text-lg">Wenn Sie technische Probleme haben, wenden Sie sich bitte an unseren Support. Wir helfen Ihnen gerne weiter und unterstützen Sie bei jedem Schritt des Prozesses.</p>
  </div>
  <app-footer></app-footer>