<div *ngIf="this.authService.currentUserSignal()?.role === 'undertaker'">
    <app-sidebar></app-sidebar>

    <div class="pl-96 pr-24 pt-12 h-screen">
        <h3 class="text-3xl font-semibold mb-10">Dashboard</h3>
        <h2 class="text-5xl font-semibold mb-10 maemona-headline">Willkommen zurück!</h2>
        <div class="flex flex-wrap">
            <div class="max-w-sm bg-gray-100 rounded-3xl p-5 text-center mr-5 mt-5">
                <p class="font-normal text-lg">Neue Trauernanzeige</p>
                <button (click)="isAnnouncementAddDialogVisible = true" class="pb-2 w-20 h-20 mt-7 text-7xl rounded-full bg-black text-white hover:bg-gray-700 duration-300">+</button>
            </div>
            <div class="max-w-sm bg-gray-100 rounded-3xl p-5 text-center mr-5 mt-5">
                <p class="font-normal text-lg">Anzahl Trauernanzeigen</p>
                <p class="font-bold text-5xl mt-2 mb-7 text-black-700">{{ announcementList.length }}</p>
                <a [routerLink]="['/traueranzeigen']" class="text-xs bg-transparent text-gray-600 border border-gray-500 rounded-3xl py-2 px-4 -mt-5 font-normal hover:bg-black hover:border-transparent hover:text-white duration-300">Übersicht</a>
                <br>
                <br>
            </div>
            <div class="max-w-sm bg-gray-100 rounded-3xl p-5 text-center mr-5 mt-5">
                <p class="font-normal text-lg">Anzahl Mitarbeiter</p>
                <p class="font-bold text-5xl mt-2 mb-7 text-black-700">{{ userList.length }}</p>
                <a [routerLink]="['/team']" class="text-xs bg-transparent text-gray-600 border border-gray-500 rounded-3xl py-2 px-4 -mt-5 font-normal hover:bg-black hover:border-transparent hover:text-white duration-300">Übersicht</a>
            </div>
        </div>
    </div>

    <p-dialog header="Anzeige anlegen" [maximizable]="true"  [modal]="true" [(visible)]="isAnnouncementAddDialogVisible">
        <app-add-edit-announcement (announcementAdded)="onAnnouncementAdded()" [companyId]="companyId"></app-add-edit-announcement>
    </p-dialog>

    <p-toast></p-toast>

</div>