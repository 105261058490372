import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CurrentRouteService {

  constructor() { }

  checkPath(targetRoute: string): boolean {
    const currentURL = window.location.href;
    const route = currentURL.split("#")[1];
    const lastSlashIndex = route.lastIndexOf('/');
    
    // Überprüfen, ob ein zusätzliches '/' vorhanden ist
    if (lastSlashIndex !== 0) {
        const path = route.substring(0, lastSlashIndex);
        return path === targetRoute;
    } else {
        // Wenn kein zusätzliches '/' vorhanden ist, vergleichen Sie den gesamten Pfad
        return route === targetRoute;
    }
}
}
