<app-navbar></app-navbar>
<div class="pb-40 pt-32 text-center px-5 md:pb-36 md:pt-52 md:px-10">
    <div class="max-w-4xl mx-auto">
        <h1 lang="de" class="text-black text-5xl font-bold mb-5 hyphens-auto">Moderne Traueranzeigen schnell und einfach erstellt</h1>
        <p class="mx-auto max-w-4xl text-lg font-light mb-10 text-gray-800">Eine neue Art Traueranzeigen für Ihre Kunden. Losgelöst von klassischen Zeitungsinseraten.</p>
        <div class="flex flex-row mx-auto w-64 bg-black text-white cursor-pointer font-medium rounded-full py-3 px-8 border border-transparent hover:bg-transparent hover:border-black hover:text-black duration-300">
            <svg class="h-7 w-7 mr-2" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />  <line x1="16" y1="2" x2="16" y2="6" />  <line x1="8" y1="2" x2="8" y2="6" />  <line x1="3" y1="10" x2="21" y2="10" /></svg>
            <a href="mailto:kontakt@maemona.de?subject=Anfrage mæmona-Demo" class="mt-0.5">Demo vereinbaren</a>
        </div>
    </div>
</div>
<svg class="w-full -mb-2" id="visual" viewBox="0 0 960 30" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><rect x="0" y="0" width="960" height="30" fill="#f3f4f6"></rect><path d="M0 9L17.8 9.2C35.7 9.3 71.3 9.7 106.8 9.5C142.3 9.3 177.7 8.7 213.2 7.8C248.7 7 284.3 6 320 6.7C355.7 7.3 391.3 9.7 426.8 10.5C462.3 11.3 497.7 10.7 533.2 10C568.7 9.3 604.3 8.7 640 9C675.7 9.3 711.3 10.7 746.8 10.3C782.3 10 817.7 8 853.2 7.3C888.7 6.7 924.3 7.3 942.2 7.7L960 8L960 0L942.2 0C924.3 0 888.7 0 853.2 0C817.7 0 782.3 0 746.8 0C711.3 0 675.7 0 640 0C604.3 0 568.7 0 533.2 0C497.7 0 462.3 0 426.8 0C391.3 0 355.7 0 320 0C284.3 0 248.7 0 213.2 0C177.7 0 142.3 0 106.8 0C71.3 0 35.7 0 17.8 0L0 0Z" fill="#ffffff" stroke-linecap="round" stroke-linejoin="miter"></path></svg> 
<div class="bg-gray-100 pt-20 py-20 px-5 md:px-10">
    <div class="max-w-4xl mx-auto flex flex-col-reverse md:flex-row">
        <img src="assets/img/office.jpg" class="shadow-lg rounded-3xl md:mr-10 w-9/10 object-cover max-w-96 mt-10 md:mt-0">
        <div class="mx-5 flex-1">
        <h2 class="text-3xl font-bold -mb-5 hyphens-auto maemona-headline">Angehörige im Trauerfall unterstützen</h2>
        <p class="my-10 text-xl font-light">Bieten Sie Ihren Kunden mit <span class="maemona-tag">mæmona</span> die Möglichkeit an, persönliche Erinnerungen an Verstorbene zeitgemäß zu veröffentlichen und mit der Gemeinschaft zu teilen. Profitieren Sie von schlanken Prozessen und guter Bedienbarkeit. Bei Interesse wenden Sie sich bitte an uns, um den Aufnahmeprozess zu starten.</p>
        <a class="bg-black text-white border border-transparent rounded-3xl py-3 px-8 mt-10 font-light hover:bg-gray-700 duration-300" href="mailto:kontakt@maemona.de?subject=Registrierungsanfrage mæmona">Kontakt</a>
        </div>
    </div>
</div>
<svg class="w-full" id="visual" viewBox="0 0 960 30" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><rect x="0" y="0" width="960" height="30" fill="#f3f4f6"></rect><path d="M0 21L17.8 20.8C35.7 20.7 71.3 20.3 106.8 20.5C142.3 20.7 177.7 21.3 213.2 22C248.7 22.7 284.3 23.3 320 22.7C355.7 22 391.3 20 426.8 19.3C462.3 18.7 497.7 19.3 533.2 19.8C568.7 20.3 604.3 20.7 640 20.3C675.7 20 711.3 19 746.8 19.3C782.3 19.7 817.7 21.3 853.2 21.8C888.7 22.3 924.3 21.7 942.2 21.3L960 21L960 31L942.2 31C924.3 31 888.7 31 853.2 31C817.7 31 782.3 31 746.8 31C711.3 31 675.7 31 640 31C604.3 31 568.7 31 533.2 31C497.7 31 462.3 31 426.8 31C391.3 31 355.7 31 320 31C284.3 31 248.7 31 213.2 31C177.7 31 142.3 31 106.8 31C71.3 31 35.7 31 17.8 31L0 31Z" fill="#ffffff" stroke-linecap="round" stroke-linejoin="miter"></path></svg>
<div class="py-20 mx-auto max-w-4xl px-5 md:px-10 text-center">
    <img class="mx-auto w-14 mb-5" src="assets/img/usability.png">
    <h2 class="text-3xl font-bold text-black mb-8 hyphens-auto maemona-headline">Schnell und unkompliziert Traueranzeigen erstellen</h2>
    <p class="mx-auto max-w-3xl font-light mb-10 text-xl">Die Zeit der komplizierten und zeitraubenden Prozesse bei der Erstellung und Verwaltung von Traueranzeigen gehört der Vergangenheit an. Unsere Plattform wurde gemeinsam mit Bestattungsfachkräften entwickelt, mit dem Ziel, möglichst schlanke Prozesse sicherzustellen. Dank unserer benutzerfreundlichen Oberfläche können Sie in kürzester Zeit hochwertige und individuelle Anzeigen erstellen, ohne dabei wertvolle Zeit zu verlieren.</p>
</div>
<app-footer></app-footer>