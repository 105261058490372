<div class="w-72 h-full fixed z-10 bg-gray-50 top-0 left-0 flex flex-col border-r border-gray-100">
    <a [routerLink]="['/']"><img class="w-52 mx-auto mt-14 mb-2" src="assets/logo.png"></a>
    <p class="mb-12 mx-auto text-gray-500 font-light">Admin</p>
    <div [class.sidebar-active]="isActive('/admin-dashboard')" class="flex flex-row ml-8 mb-7 items-center text-gray-500 hover:text-black divide-purple-300 cursor-pointer">
        <svg class="mr-2 h-5 w-5" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />  <polyline points="9 22 9 12 15 12 15 22" /></svg>
        <a [routerLink]="['/admin-dashboard']">Dashboard</a>
    </div>
    <div [class.sidebar-active]="isActive('/admin-traueranzeigen')" class="flex flex-row ml-8 mb-7 items-center text-gray-500 hover:text-black duration-300 cursor-pointer">
        <svg class="mr-2 h-5 w-5"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"/>
        </svg>              
        <a [routerLink]="['/admin-traueranzeigen']">Zeitungsanzeigen</a>
    </div>
    <div [class.sidebar-active]="isActive('/bestattungsinstitute')" class="flex flex-row ml-8 mb-7 items-center text-gray-500 hover:text-black duration-300 cursor-pointer">
        <svg class="mr-2 h-5 w-5"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="3" y1="21" x2="21" y2="21" />  <line x1="9" y1="8" x2="10" y2="8" />  <line x1="9" y1="12" x2="10" y2="12" />  <line x1="9" y1="16" x2="10" y2="16" />  <line x1="14" y1="8" x2="15" y2="8" />  <line x1="14" y1="12" x2="15" y2="12" />  <line x1="14" y1="16" x2="15" y2="16" />  <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16" />
        </svg>              
        <a [routerLink]="['/bestattungsinstitute']">Bestattungsinstitute</a>
    </div>
    <div [class.sidebar-active]="isActive('/admin-team')" class="flex flex-row ml-8 mb-7 items-center text-gray-500 hover:text-black duration-300 cursor-pointer">
        <svg class="mr-2 h-5 w-5"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />  <circle cx="9" cy="7" r="4" />  <path d="M23 21v-2a4 4 0 0 0-3-3.87" />  <path d="M16 3.13a4 4 0 0 1 0 7.75" /></svg>
        <a [routerLink]="['/admin-team']">Team</a>
    </div>
    <div class="flex flex-row ml-8 mb-7 items-center text-gray-500 hover:text-black duration-300 cursor-pointer">
        <svg class="mr-2 h-5 w-5"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />  <line x1="12" y1="17" x2="12.01" y2="17" /></svg>
        <a [routerLink]="['/hilfe']">Hilfe</a>
    </div>
    <div class="mt-auto p-8 text-center">
        <p class="text-gray-400 font-light text-sm">Angemeldet als:</p>
        <p class="mb-5 text-gray-800 break-words hyphens-auto">{{ this.authService.currentUserSignal()?.firstName }} {{ this.authService.currentUserSignal()?.lastName }}</p>
        <button (click)="logout()" class="w-full mb-5 no-underline py-2 font-light px-3 bg-black text-white border border-transparent rounded-3xl hover:bg-gray-700 duration-300">Abmelden</button>
    </div>
</div>