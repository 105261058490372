<nav class="fixed top-0 left-0 right-0 z-20 md:flex items-center justify-between py-4 px-5 md:justify-start bg-white shadow-md">
    <div class="flex">
      <a href="">
        <img src="assets/img/logo.png" class="h-5">
      </a>
    </div>
    <button id="burger-menu" class="md:hidden focus:outline-none fixed right-0 top-0 pt-4 pr-5" onclick="Menu(this)">
      <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
      </svg>
    </button>
    <ul id="menu-items" class="hidden md:flex-1 md:flex md:items-center md:justify-end closed mt-5 md:mt-0">
      <li [class.nav-active]="isActive('/')" class="py-1 px-3"><a class="no-underline hover:text-gray-500 duration-300 font-light" [routerLink]="['/']">Für Angehörige</a></li>
      <li [class.nav-active]="isActive('/fuer-bestattungsinstitute')" class="py-1 px-3"><a class="no-underline hover:text-gray-500 duration-300 font-light" [routerLink]="['/fuer-bestattungsinstitute']">Für Bestattungsinstitute</a></li>
      <li [class.nav-active]="isActive('/hilfe')"class="py-1 px-3"><a class="no-underline hover:text-gray-500 duration-300 font-light" [routerLink]="['/hilfe']">Hilfe</a></li>
      <div *ngIf="!this.authService.currentUserSignal()">
        <li class="py-1 px-3 mt-2 -ml-3 md:ml-0  md:mt-0"><a class="no-underline font-light py-2 px-3 bg-black text-white border border-transparent rounded-3xl hover:bg-gray-700 duration-300" [routerLink]="['/login']">Anmelden</a></li>
      </div>
      <div *ngIf="this.authService.currentUserSignal() && this.authService.currentUserSignal()?.role === 'undertaker'">
        <li class="py-1 px-3 mt-3 -ml-3 md:ml-0  md:mt-0"><a [routerLink]="['/dashboard']" class="no-underline py-2 font-light px-3 bg-black text-white border border-transparent rounded-3xl hover:bg-gray-700 duration-300">Zur App</a></li>
      </div>
      <div *ngIf="this.authService.currentUserSignal() && this.authService.currentUserSignal()?.role === 'admin'">
        <li class="py-1 px-3 mt-3 -ml-3 md:ml-0  md:mt-0"><a [routerLink]="['/admin-dashboard']" class="no-underline py-2 font-light px-3 bg-black text-white border border-transparent rounded-3xl hover:bg-gray-700 duration-300">Zur App</a></li>
      </div>
    </ul>
  </nav> 