import { Component, inject, EventEmitter, Output, Input, OnChanges } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { UsersService } from '../../../../services/users.service';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { NgIf } from '@angular/common';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-add-edit-user',
  standalone: true,
  imports: [
    ButtonModule,
    ReactiveFormsModule,
    InputTextModule,
    InputNumberModule,
    InputSwitchModule,
    NgIf
  ],
  templateUrl: './add-edit-user.component.html',
  styleUrl: './add-edit-user.component.css'
})
export class AddEditUserComponent implements OnChanges {
  @Output() userEdited: EventEmitter<void> = new EventEmitter<void>();
  @Output() userAdded: EventEmitter<void> = new EventEmitter<void>();
  @Input() selectedUser: any = null;
  @Input() companyId: any = null;

  usersService: UsersService = inject(UsersService);
  authService: AuthService = inject(AuthService);

  userForm = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    email: new FormControl(''),
    password: new FormControl(''),
    verified: new FormControl(false)
  });

  ngOnChanges(): void {
    if(this.selectedUser) {
      this.userForm.patchValue(this.selectedUser);
    } else {
      this.userForm.reset();
    }
  }

  addOrEditUser() {
    if(this.selectedUser) {
      this.usersService.editUser(
        this.selectedUser.userId,
        this.selectedUser.companyId,
        this.selectedUser.role,
        this.userForm.value.email ?? '',
        this.userForm.value.password ?? '',
        this.userForm.value.firstName ?? '',
        this.userForm.value.lastName ?? '',
        this.userForm.value.verified ?? false).then(() => {
          console.log("User edited")
          this.userForm.reset();
          this.userEdited.emit();
      });
    } else {
      this.usersService.createUser(
        this.companyId,
        "undertaker",
        this.userForm.value.email ?? '',
        this.userForm.value.password ?? ``,
        this.userForm.value.firstName ?? '',
        this.userForm.value.lastName ?? '',
        this.userForm.value.verified ?? false).then(() => {
          console.log("User created")
          this.userForm.reset();
          this.userAdded.emit();
      });
    }
  }

}
