<div class="w-72 h-full fixed z-10 bg-gray-50 top-0 left-0 flex flex-col border-r border-gray-100">
    <a [routerLink]="['/']"><img class="w-52 mx-auto mt-14 mb-12" src="assets/logo.png"></a>
    <div [class.sidebar-active]="isActive('/dashboard')" class="flex flex-row ml-8 mb-7 items-center text-gray-500 hover:text-black divide-purple-300 cursor-pointer">
        <svg class="mr-2 h-5 w-5" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />  <polyline points="9 22 9 12 15 12 15 22" /></svg>
        <a [routerLink]="['/dashboard']" class="">Dashboard</a>
    </div>
    <div [class.sidebar-active]="isActive('/traueranzeigen')" class="flex flex-row ml-8 mb-7 items-center text-gray-500 hover:text-black duration-300 cursor-pointer">
        <svg class="mr-2 h-5 w-5"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"/>
        </svg>              
        <a [routerLink]="['/traueranzeigen']" class="">Traueranzeigen</a>
    </div>
    <div [class.sidebar-active]="isActive('/team')" class="flex flex-row ml-8 mb-7 items-center text-gray-500 hover:text-black duration-300 cursor-pointer">
        <svg class="mr-2 h-5 w-5"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />  <circle cx="9" cy="7" r="4" />  <path d="M23 21v-2a4 4 0 0 0-3-3.87" />  <path d="M16 3.13a4 4 0 0 1 0 7.75" /></svg>
        <a [routerLink]="['/team']" class="">Team</a>
    </div>
    <div class="flex flex-row ml-8 mb-7 items-center text-gray-500 hover:text-black duration-300 cursor-pointer">
        <svg class="mr-2 h-5 w-5"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />  <line x1="12" y1="17" x2="12.01" y2="17" /></svg>
        <a class="" [routerLink]="['/hilfe']">Hilfe</a>
    </div>
    <div class="mt-auto mx-auto p-8 text-center">
        <p class="text-gray-400 font-light text-sm">Angemeldet als:</p>
        <div class="flex flex-row items-center mt-2">
            <p class="mb-5 text-gray-800 break-words hyphens-auto">{{ this.authService.currentUserSignal()?.firstName }} {{ this.authService.currentUserSignal()?.lastName }}</p>
            <button (click)="isPasswordChangeDialogVisible = true" class="bg-transparent text-gray-400 hover:text-gray-600 duration-300">
                <svg class="ml-2 h-6 w-6 -mt-5" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />  <circle cx="12" cy="12" r="3" />
                </svg> 
            </button>
        </div>
        <button (click)="logout()" class="w-full mb-5 no-underline py-2 font-light px-3 bg-black text-white border border-transparent rounded-3xl hover:bg-gray-700 duration-300">Abmelden</button>
    </div>
</div>
<p-dialog header="Passwort ändern" [maximizable]="false"  [modal]="true" [(visible)]="isPasswordChangeDialogVisible">
    <app-change-password (passwordChanged)="onPasswordChanged()"></app-change-password>
</p-dialog>