<footer class="px-5 py-5 text-center relative font-light">
    <div class="text-sm text-gray-400">
        <a href="https://ffwdventures.de" class="mb-3 hover:text-black duration-300">©2024 FFWD Ventures</a>
        <div class="flex flex-wrap justify-center">
            <a [routerLink]="['/impressum']" class="mx-2 my-1 hover:text-black duration-300">Impressum</a>
            <a [routerLink]="['/datenschutz']" class="mx-2 my-1 hover:text-black duration-300">Datenschutz</a>
            <a [routerLink]="['/nutzungsbedingungen']" class="mx-2 my-1 hover:text-black duration-300">Nutzungsbedingungen</a>
        </div>
    </div>
</footer> 
