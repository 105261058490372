<app-navbar></app-navbar>
<div class="max-w-5xl mx-auto mt-28">
    <div class="mx-5 flex-1 text-center">
      <h2 class="text-5xl font-bold mb-10 md:mt-14 hyphens-auto maemona-headline">Nutzungsbedingungen</h2>
      <p class="mx-auto max-w-3xl font-light mb-10 text-xl">Bitte lesen Sie diese Nutzungsbedingungen sorgfältig durch, bevor Sie unsere Webseite nutzen.</p>
    </div>
  </div>
  <svg class="w-full -mb-2" viewBox="0 0 960 30" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><rect x="0" y="0" width="960" height="30" fill="#f3f4f6"></rect><path d="M0 9L17.8 9.2C35.7 9.3 71.3 9.7 106.8 9.5C142.3 9.3 177.7 8.7 213.2 7.8C248.7 7 284.3 6 320 6.7C355.7 7.3 391.3 9.7 426.8 10.5C462.3 11.3 497.7 10.7 533.2 10C568.7 9.3 604.3 8.7 640 9C675.7 9.3 711.3 10.7 746.8 10.3C782.3 10 817.7 8 853.2 7.3C888.7 6.7 924.3 7.3 942.2 7.7L960 8L960 0L942.2 0C924.3 0 888.7 0 853.2 0C817.7 0 782.3 0 746.8 0C711.3 0 675.7 0 640 0C604.3 0 568.7 0 533.2 0C497.7 0 462.3 0 426.8 0C391.3 0 355.7 0 320 0C284.3 0 248.7 0 213.2 0C177.7 0 142.3 0 106.8 0C71.3 0 35.7 0 17.8 0L0 0Z" fill="#ffffff" stroke-linecap="round" stroke-linejoin="miter"></path></svg> 
  <div class="bg-gray-100 pt-10 pb-10 px-5 md:px-10 text-center" id="for-all">
    <p class="font-bold text-black hyphens-auto text-lg">1. Allgemeines</p>
    <p class="mx-auto max-w-3xl font-light mb-5 text-lg">Diese Nutzungsbedingungen regeln die Nutzung der Webseite <span class="maemona-tag">mæmona</span> (im Folgenden "Webseite" genannt), die von FFWD Florian Werner Digital Ventures (im Folgenden "Anbieter" genannt) betrieben wird. Durch die Nutzung dieser Webseite erklären Sie sich mit diesen Nutzungsbedingungen einverstanden.</p>
    <p class="font-bold text-black hyphens-auto text-lg">2. Nutzungsrechte</p>
    <p class="mx-auto max-w-3xl font-light mb-5 text-lg">Der Anbieter gewährt Ihnen das nicht ausschließliche, nicht übertragbare Recht, auf die Webseite und die dort bereitgestellten Inhalte zuzugreifen und sie für Ihren persönlichen Gebrauch zu nutzen.</p>
    <p class="font-bold text-black hyphens-auto text-lg">3. Registrierung</p>
    <p class="mx-auto max-w-3xl font-light mb-5 text-lg">Bestattungsinstitute können sich über die Webseite registrieren, um bestimmte Funktionen wie das Erstellen und Bearbeiten von Anzeigen zu nutzen. Bei der Registrierung sind Sie verpflichtet, korrekte und vollständige Informationen anzugeben. Sie sind allein verantwortlich für die Sicherheit Ihres Kontos und Passworts.</p>
    <p class="font-bold text-black hyphens-auto text-lg">4. Verantwortlichkeit für Inhalte</p>
    <p class="mx-auto max-w-3xl font-light mb-5 text-lg">Sie sind allein verantwortlich für alle Inhalte, die Sie auf der Webseite veröffentlichen. Sie erklären sich damit einverstanden, keine rechtswidrigen, beleidigenden, diffamierenden oder anderweitig unangemessenen Inhalte zu veröffentlichen.</p>
    <p class="font-bold text-black hyphens-auto text-lg">5. Haftungsausschluss</p>
    <p class="mx-auto max-w-3xl font-light mb-5 text-lg">Der Anbieter übernimmt keine Haftung für die Richtigkeit, Vollständigkeit oder Aktualität der auf der Webseite bereitgestellten Inhalte. Die Nutzung der Webseite erfolgt auf eigene Gefahr.</p>
    <p class="font-bold text-black hyphens-auto text-lg">6. Änderungen der Nutzungsbedingungen</p>
    <p class="mx-auto max-w-3xl font-light mb-5 text-lg">Der Anbieter behält sich das Recht vor, diese Nutzungsbedingungen jederzeit zu ändern. Die geänderten Bedingungen treten mit ihrer Veröffentlichung auf der Webseite in Kraft.</p>
    <p class="font-bold text-black hyphens-auto text-lg">7. Schlussbestimmungen</p>
    <p class="mx-auto max-w-3xl font-light mb-5 text-lg">Diese Nutzungsbedingungen unterliegen deutschem Recht. Gerichtsstand für Streitigkeiten aus oder im Zusammenhang mit diesen Nutzungsbedingungen ist Erlangen. Sollten einzelne Bestimmungen dieser Nutzungsbedingungen ganz oder teilweise unwirksam sein oder werden, bleibt die Wirksamkeit der übrigen Bestimmungen hiervon unberührt.</p>
    <p class="mx-auto max-w-3xl font-light mb-5 text-lg">Bei Fragen zu diesen Nutzungsbedingungen wenden Sie sich bitte an kontakt[ät]maemona.de.</p>
  </div>
  <app-footer></app-footer>