import { Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { Company } from '../../../../interfaces/company';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CompaniesService } from '../../../../services/companies.service';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { AddEditCompanyComponent } from '../add-edit-company/add-edit-company.component';
import { NgIf } from '@angular/common';
import { AdminSidebarComponent } from '../admin-sidebar/admin-sidebar.component';

@Component({
  selector: 'app-institutes',
  standalone: true,
  imports: [
    RouterModule,
    TableModule,
    ButtonModule,
    DialogModule,
    ConfirmDialogModule,
    ToastModule,
    AddEditCompanyComponent,
    NgIf,
    AdminSidebarComponent
  ],
  providers: [ConfirmationService, MessageService],
  templateUrl: './institutes.component.html',
  styleUrl: './institutes.component.css'
})
export class InstitutesComponent {
  authService: AuthService = inject(AuthService);
  companyList: Company[] = [];
  company!: Company;
  companiesService: CompaniesService = inject(CompaniesService);
  isAddDialogVisible: boolean = false;
  isEditDialogVisible: boolean = false;
  selectedCompany: any = null;
  totalRecords!: number;
  rowsPerPageOptions = [5,10,20];

  constructor(private router: Router, private messageService: MessageService, private confirmationService: ConfirmationService) {}

  async ngOnInit(): Promise<void> {
    await this.authService.waitForCurrentUserSignal();
    this.fetchCompanies();
    if (this.authService.currentUserSignal() == null) {
      this.router.navigate(['/login']);
    } else if (this.authService.currentUserSignal()?.role != 'admin') {
      this.router.navigate(['/dashboard']);
    }
  }

  fetchCompanies($event?: TableLazyLoadEvent) {
    this.companiesService.getAllCompanies($event?.first || 0, $event?.rows || 5).then((companyData: any) => {
      this.totalRecords = companyData.totalItems;
      this.companyList = companyData.items;
    });
  }

  onCompanyAdded() {
    this.fetchCompanies();
    this.isAddDialogVisible = false;
    this.messageService.add({ severity: 'success', summary: 'Anlegen erfolgreich', detail: "Unternehmen wurde angelegt" });
  }

  onCompanyEdited() {
    this.fetchCompanies();
    this.isEditDialogVisible = false;
    this.messageService.add({ severity: 'success', summary: 'Bearbeiten erfolgreich', detail: "Unternehmen wurde bearbeitet" });
  }

  deleteCompany(id: string) {
    this.companiesService.getCompanyById(id).then((company: Company | undefined) => {
      if (company) {
        this.confirmationService.confirm({
            message: company.companyName+" wirkllich löschen?",
            acceptLabel: "Ja",
            rejectLabel: "Nein",
            acceptButtonStyleClass: "p-button-danger",
            acceptIcon:"none",
            rejectIcon:"none",
            accept: () => {
              this.companiesService.deleteCompany(id).then(() => {
                this.fetchCompanies();
                this.messageService.add({ severity: 'success', summary: 'Löschen bestätigt', detail: company.companyName+" wurde gelöscht" });
              })
              .catch((error) => {
                console.log(error);
                this.messageService.add({ severity: 'error', summary: 'Fehler beim Löschen', detail: 'Das Löschen des Unternehmens ' + company.companyName + ' ist fehlgeschlagen' });
              });
            }
        });
      }
    });
  }

  showAddDialog() {
    this.isAddDialogVisible = true;
  }

  showEditDialog(company: Company) {
    this.isEditDialogVisible = true;
    this.selectedCompany = company;
  }
}
