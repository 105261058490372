import { Injectable } from '@angular/core';
import { Announcement } from '../interfaces/announcement';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementsService {
  url = environment.apiUrl + "announcements"
  fileUploadUrl = environment.apiUrl + "announcementfilesbucket/"
  apiKey = localStorage.getItem("subUserId")!;

  constructor() { }

  async getAllAnnouncements(): Promise<Announcement[]> {
    try {
      const response = await fetch(this.url, {
        headers: {
          'x-api-key': this.apiKey
        }
      });
      const data = await response.json();
      return data.items ?? [];
    } catch (error) {
      console.error('Fehler beim Abrufen aller Ankündigungen:', error);
      return [];
    }
  }
  
  async getAllAnnouncementsForCompany(companyId: string, skip?: number, limit?: number): Promise<any> {
    try {
      let url = `${this.url}?companyId=${companyId}&all=true`;
      if (skip !== undefined) {
        url += `&skip=${skip}`;
      }
      if (limit !== undefined) {
        url += `&limit=${limit}`;
      }
      const response = await fetch(url, {
        headers: {
          'x-api-key': this.apiKey
        }
      });
      const data = await response.json();
      return data ?? {};
    } catch (error) {
      console.error('Fehler beim Abrufen von Ankündigungen für ein Unternehmen:', error);
      return {};
    }
  }
  
  async getAnnouncementById(id: string): Promise<Announcement | undefined> {
    try {
      const response = await fetch(`${this.url}/${id}`, {
        headers: {
          'x-api-key': this.apiKey
        }
      });
      const data = await response.json();
      return data ?? {};
    } catch (error) {
      console.error('Fehler beim Abrufen einer Ankündigung anhand der ID:', error);
      return undefined;
    }
  }
  

  async deleteAnnouncement(id: string): Promise<void> {
    const response = await fetch(`${this.url}/${id}`, {
      method: 'DELETE',
      headers: {
        'x-api-key': this.apiKey
      }
    });
  }

  async createAnnouncement(companyId: string, userId: string, city: string, postalCode: string, country: string, pictureURL: string | undefined = undefined, additionalMedia: string | undefined = undefined, firstName: string, lastName: string, text: string | undefined = undefined, dayOfDeath: string,
    dayOfBirth: string, burialDate: string | undefined = undefined, burialLocation: string | undefined = undefined, burialText: string | undefined = undefined, religion: string | undefined = undefined, isOriginal: boolean, isPublic: boolean): Promise<void> {    
    const announcement: Announcement = {
      companyId: companyId,
      userId: userId,
      city: city,
      postalCode: postalCode,
      country: country,
      pictureURL: pictureURL,
      additionalMedia: additionalMedia,
      firstName: firstName,
      lastName: lastName,
      text: text,
      dayOfDeath: dayOfDeath,
      dayOfBirth: dayOfBirth,
      burialDate: burialDate,
      burialLocation: burialLocation,
      burialText: burialText,
      religion: religion,
      isOriginal: isOriginal,
      isPublic: isPublic,
    };
    await fetch(this.url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': this.apiKey
      },
      body: JSON.stringify(announcement)
    });
  }

  async editAnnouncement(announcementId: string, companyId: string, userId: string, city: string, postalCode: string, country: string, pictureURL: string | undefined = undefined, additionalMedia: string | undefined = undefined, firstName: string, lastName: string, text: string | undefined = undefined, dayOfDeath: string,
    dayOfBirth: string, burialDate: string | undefined = undefined, burialLocation: string | undefined = undefined, burialText: string | undefined = undefined, religion: string | undefined = undefined, isOriginal: boolean, isPublic: boolean): Promise<void> {    
      const announcement: Announcement = {
      companyId: companyId,
      userId: userId,
      city: city,
      postalCode: postalCode,
      country: country,
      pictureURL: pictureURL,
      additionalMedia: additionalMedia,
      firstName: firstName,
      lastName: lastName,
      text: text,
      dayOfDeath: dayOfDeath,
      dayOfBirth: dayOfBirth,
      burialDate: burialDate,
      burialLocation: burialLocation,
      burialText: burialText,
      religion: religion,
      isOriginal: isOriginal,
      isPublic: isPublic,
    };
    await fetch(`${this.url}/${announcementId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': this.apiKey
      },
      body: JSON.stringify(announcement)
    });
  }

  async uploadFile(file: File): Promise<string> {   
    const uniqueFileName = this.generateUniqueFileName(file); // Generieren Sie einen einzigartigen Dateinamen
    const fileUrl = this.fileUploadUrl + uniqueFileName;
  
    try {
        const response = await fetch(fileUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': file.type,
              'x-api-key': this.apiKey
          },
            body: file
        });
  
        if (!response.ok) {
            throw new Error('Fehler beim Hochladen der Datei');
        }
  
        return uniqueFileName;
    } catch (error) {
        throw new Error('Fehler beim Hochladen der Datei');
    }
  }

  async deleteFile(fileName: string): Promise<void> {
    try {
      const response = await fetch(`${this.fileUploadUrl}${fileName}`, {
        method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': this.apiKey
      }
      });

      if (!response.ok) {
          throw new Error('Fehler beim Löschen der Datei');
      }
  } catch (error) {
      throw new Error('Fehler beim Löschen der Datei');
  }
  }

  generateUniqueFileName(file: File): string {
      const timestamp = new Date().getTime(); // Aktuelle Zeitstempel
      const randomString = Math.random().toString(36).substring(7); // Zufällige Zeichenfolge
      const fileExtension = this.getFileExtension(file.name); // Dateierweiterung aus dem ursprünglichen Dateinamen extrahieren
      return `${timestamp}_${randomString}.${fileExtension}`; // Kombinieren Sie Zeitstempel, Zufallszeichenfolge und Dateierweiterung
  }

  getFileExtension(fileName: string): string {
      return fileName.split('.').pop() ?? ''; // Extrahieren Sie die Dateierweiterung aus dem Dateinamen
  }

}
