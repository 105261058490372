import { Component, inject, EventEmitter, Output, Input, OnChanges, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CompaniesService } from '../../../../services/companies.service';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { LocationsService } from '../../../../services/locations.service';
import { NgIf } from '@angular/common';
import { AuthService } from '../../../../services/auth.service';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';

@Component({
  selector: 'app-add-edit-company',
  standalone: true,
  imports: [
    ButtonModule,
    ReactiveFormsModule,
    InputTextModule,
    InputNumberModule,
    DropdownModule,
    NgIf,
    AutoCompleteModule
  ],
  templateUrl: './add-edit-company.component.html',
  styleUrl: './add-edit-company.component.css'
})
export class AddEditCompanyComponent implements OnChanges, OnInit{
  @Output() companyEdited: EventEmitter<void> = new EventEmitter<void>();
  @Output() companyAdded: EventEmitter<void> = new EventEmitter<void>();
  @Input() selectedCompany: any = null;
  authService: AuthService = inject(AuthService);

  companiesService: CompaniesService = inject(CompaniesService)
  postalCodeOptions: any[] = [];
  cityOptions: String[] = [];
  selectedPostalCode: any;
  postalCodeSuggestions: any[] = [];

  locationsService: LocationsService = inject(LocationsService)

  companyForm = new FormGroup({
    companyName: new FormControl(''),
    phoneNumber: new FormControl(''),
    address: new FormControl(''),
    postalCode: new FormControl(null),
    city: new FormControl({value: '', disabled: true}),
    country: new FormControl('Deutschland'),
    email: new FormControl(''),
    website: new FormControl('')
  });

  ngOnChanges(): void {
    if(this.selectedCompany) {
      this.companyForm.patchValue(this.selectedCompany);
      if (this.selectedCompany.postalCode != '') {
        this.locationsService.getDistrictsByPostalCode(this.companyForm.value.postalCode!).then((regions: String[]) => {
          this.cityOptions = regions;
          this.cityOptions.shift();
          this.companyForm.controls['city'].setValue(this.selectedCompany.city);
          this.companyForm.controls['city'].enable();
        });
      }
    } else {
      this.companyForm.reset();
    }
  }

  ngOnInit(): void {
  }

  onPostalCodeChange() {
    this.locationsService.getDistrictsByPostalCode(this.companyForm.value.postalCode!).then((regions: String[]) => {
      if (regions.length != 0) {
        this.cityOptions = regions;
        this.companyForm.controls['city'].setValue(this.cityOptions[0].toString());
        this.companyForm.controls['city'].enable();
      } else {
        this.companyForm.controls['city'].disable();
        this.companyForm.controls['city'].setValue("");
      }
    });
  }

  searchPostalCode(event: AutoCompleteCompleteEvent) {
    this.locationsService.searchPostalCodes(event.query).then((postalCodes: string[]) => {
      this.postalCodeSuggestions = postalCodes;
    });
  }

  addOrEditCompany() {
    if(this.selectedCompany) {
      this.companiesService.editCompany(
        this.selectedCompany.companyId,
        this.companyForm.value.companyName ?? '',
        this.companyForm.value.phoneNumber ?? '',
        this.companyForm.value.address ?? '',
        this.companyForm.value.city ?? '',
        this.companyForm.value.postalCode ?? '',
        this.companyForm.value.country ?? '',
        this.companyForm.value.email ?? '',
        this.companyForm.value.website ?? '').then(() => {
          console.log("Company edited")
          this.companyForm.reset();
          this.companyEdited.emit();
      });
    }
    else {
      this.companiesService.createCompany(
        this.companyForm.value.companyName ?? '',
        this.companyForm.value.phoneNumber ?? '',
        this.companyForm.value.address ?? '',
        this.companyForm.value.city ?? '',
        this.companyForm.value.postalCode ?? '',
        this.companyForm.value.country ?? '',
        this.companyForm.value.email ?? '',
        this.companyForm.value.website ?? '').then(() => {
        console.log("Company created")
        this.companyForm.reset();
        this.companyAdded.emit();
      });
    }
  }
}
