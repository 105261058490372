import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie-consent',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './cookie-consent.component.html',
  styleUrl: './cookie-consent.component.css'
})

export class CookieConsentComponent implements OnInit {
  cookieAccepted = false;

  constructor() { }

  ngOnInit(): void {
    this.cookieAccepted = localStorage.getItem('cookieAccepted') === 'true';
  }

  acceptCookies() {
    this.cookieAccepted = true;
    localStorage.setItem('cookieAccepted', 'true');
  }
}
