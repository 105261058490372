<div *ngIf="this.authService.currentUserSignal()?.role === 'admin'">
    <app-admin-sidebar></app-admin-sidebar>

    <div class="pl-96 pr-24 h-screen">
        <div class="flex flex-row items-center mt-12">
            <h3 class="text-3xl font-semibold mb-5">Zeitungsanzeigen</h3>
            <button class="text-xs bg-transparent text-black border border-gray-500 rounded-3xl py-2 px-4 ml-4 -mt-5 font-normal hover:bg-black hover:border-transparent hover:text-white duration-300" *ngIf="this.authService.currentUserSignal()?.verified" (click)="showAnnouncementAddDialog(companyId)">Neue Anzeige</button>
        </div>  
        <p-table [value]="announcementList" [tableStyle]="{ 'min-width': '50rem' }" [paginator]="true" [rows]="5" [rowsPerPageOptions]="rowsPerPageOptions" [lazy]="true" (onLazyLoad)="fetchAnnouncements($event)" [totalRecords]="totalRecords">
            <ng-template pTemplate="header">
                <tr>
                    <th>Name</th>
                    <th>Hauptbild</th>
                    <th>Stadt/Landkreis</th>
                    <th>Geburtstag</th>
                    <th>Todestag</th>
                    <th>Angelegt von</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-announcement>
                <tr>
                    <td>{{ announcement.firstName }} {{ announcement.lastName }}</td>
                    <td class="text-center">
                        <div class="w-10 mx-auto rounded-xl m-0 p-0">
                            <p-image [src]="url + announcement.pictureURL" [alt]="'Hauptbild für' + announcement.firstName + ' ' + announcement.lastName" height="50" *ngIf="announcement.pictureURL !=''" [preview]="true"></p-image>
                            <img [src]="url + 'profile-icon.jpg'" alt="Hauptbild" *ngIf="announcement.pictureURL ==''">
                        </div>
                    <td>{{ announcement.postalCode }} {{ announcement.city }}</td>
                    <td>{{ announcement.dayOfBirth | customDateFormat: 'dateOnly' }}</td>
                    <td>{{ announcement.dayOfDeath | customDateFormat: 'dateOnly' }}</td>
                    <td>{{ announcement.userName }}</td>
                    <td *ngIf="this.authService.currentUserSignal()?.verified" class="flex flex-row">
                        <p-button class="text-gray-600 hover:bg-gray-50 rounded-full px-0 mr-2" icon="pi pi-pencil" severity="secondary" [rounded]="true" [text]="true" (onClick)="showAnnouncementEditDialog(announcement, companyId)"></p-button>
                        <p-button class="text-red-600 hover:bg-red-50 rounded-full px-0" icon="pi pi-times" severity="danger" [rounded]="true" [text]="true" (onClick)="deleteAnnouncement(announcement.announcementId)"></p-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <br>
        <br>
    </div>

    <p-dialog header="Anzeige anlegen" [modal]="true" [(visible)]="isAnnouncementAddDialogVisible">
        <app-add-edit-announcement (announcementAdded)="onAnnouncementAdded()" [companyId]="companyId"></app-add-edit-announcement>
    </p-dialog>
    <p-dialog header="Anzeige bearbeiten" [modal]="true" [(visible)]="isAnnouncementEditDialogVisible">
        <app-add-edit-announcement (announcementEdited)="onAnnouncementEdited()" [selectedAnnouncement]="selectedAnnouncement" [companyId]="companyId"></app-add-edit-announcement>
    </p-dialog>

    <p-confirmDialog header="Löschen bestätigen" icon="pi pi-exclamation-triangle"></p-confirmDialog>

    <p-toast></p-toast>
</div>
