import { Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { Announcement } from '../../../../interfaces/announcement';
import { AnnouncementsService } from '../../../../services/announcements.service';
import { Company } from '../../../../interfaces/company';
import { CompaniesService } from '../../../../services/companies.service';
import { NgIf } from '@angular/common';
import { User } from '../../../../interfaces/user';
import { UsersService } from '../../../../services/users.service';
import { MessageService } from 'primeng/api';
import { AddEditAnnouncementComponent } from '../../mutual/add-edit-announcement/add-edit-announcement.component';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { AdminSidebarComponent } from '../admin-sidebar/admin-sidebar.component';

@Component({
  selector: 'app-admin-dashboard',
  standalone: true,
  imports: [
    RouterModule,
    NgIf,
    AddEditAnnouncementComponent,
    DialogModule,
    ToastModule,
    AdminSidebarComponent
  ],
  providers: [MessageService],
  templateUrl: './admin-dashboard.component.html',
  styleUrl: './admin-dashboard.component.css'
})
export class AdminDashboardComponent {
  authService: AuthService = inject(AuthService);
  announcementsService: AnnouncementsService =inject(AnnouncementsService)
  announcementList: Announcement[] = []
  filteredAnnouncementList: Announcement[] = []
  companyId = ""
  companyList: Company[] = [];
  companiesService: CompaniesService = inject(CompaniesService);
  usersService: UsersService = inject(UsersService);
  filteredUserList: User[] = [];
  userList: User[] = [];
  isAnnouncementAddDialogVisible = false;

  constructor(private router: Router, private messageService: MessageService) {}

  async ngOnInit(): Promise<void> {
    await this.authService.waitForCurrentUserSignal();
    this.companyId = this.authService.currentUserSignal()?.companyId ?? "";
    this.fetchUsers();
    this.fetchAnnouncements();
    this.fetchCompanies();
    if (this.authService.currentUserSignal() == null) {
      this.router.navigate(['/login']);
    } else if (this.authService.currentUserSignal()?.role != 'admin') {
      this.router.navigate(['/dashboard']);
    }
  }

  

  fetchAnnouncements() {
    this.announcementsService.getAllAnnouncements().then((announcementList: Announcement[]) => {
      this.announcementList = announcementList;
      this.filteredAnnouncementList = this.announcementList.filter(announcement => announcement.companyId === this.companyId);
    });
  }

  fetchCompanies() {
    this.companiesService.getAllCompanies().then((companyList: any) => {
      this.companyList = companyList.items;
    });
  }

  fetchUsers() {
    if (this.companyId != "" ) {
      this.usersService.getAllUsers().then((userList: User[]) => {
        this.userList = userList;
        this.filteredUserList = this.userList.filter(user => user.companyId === this.companyId);
      });
    }
  }

  onAnnouncementAdded() {
    this.isAnnouncementAddDialogVisible = false;
    this.messageService.add({ severity: 'success', summary: 'Anlegen erfolgreich', detail: "Traueranzeige wurde angelegt" });
    this.fetchAnnouncements();
  }
}
