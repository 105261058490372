import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-preview',
  standalone: true,
  imports: [NgIf],
  templateUrl: './preview.component.html',
  styleUrl: './preview.component.css'
})
export class PreviewComponent {
  @Input() imageSrc: string = '';
  @Input() birthDate: string = '';
  @Input() deathDate: string = '';
  @Input() name: string = '';
  @Input() age: string = '';
  @Input() text: string = '';
  @Input() additionalMediaSrc: string = '';
  @Input() burialLocation: string = '';
  @Input() burialDate: string = '';
  @Input() burialText: string = '';
  @Input() undertakerName: string = '';
}
