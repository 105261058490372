import { Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { CustomDateFormatPipe } from '../../../../pipes/custom-date-format.pipe';
import { User } from '../../../../interfaces/user';
import { UsersService } from '../../../../services/users.service';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AddEditUserComponent } from '../add-edit-user/add-edit-user.component';
import { DialogModule } from 'primeng/dialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NgIf } from '@angular/common';
import { AdminSidebarComponent } from '../admin-sidebar/admin-sidebar.component';

@Component({
  selector: 'app-admin-team',
  standalone: true,
  imports: [
    RouterModule,
    CustomDateFormatPipe,
    TableModule,
    ConfirmDialogModule,
    AddEditUserComponent,
    DialogModule,
    NgIf,
    AdminSidebarComponent
  ],
  providers: [ConfirmationService, MessageService],
  templateUrl: './admin-team.component.html',
  styleUrl: './admin-team.component.css'
})
export class AdminTeamComponent {
  authService: AuthService = inject(AuthService);
  usersService: UsersService = inject(UsersService);
  userList: User[] = [];
  companyId = "";
  isEditDialogVisible = false;
  isAddDialogVisible = false;
  selectedUser: any = null;
  totalRecords!: number;
  rowsPerPageOptions = [5,10,20];

  constructor(private router: Router, private confirmationService: ConfirmationService, private messageService: MessageService) {}

  async ngOnInit(): Promise<void> {
    await this.authService.waitForCurrentUserSignal();
    this.companyId = this.authService.currentUserSignal()?.companyId ?? "";
    this.fetchUsers();
    if (this.authService.currentUserSignal() == null) {
      this.router.navigate(['/login']);
    } else if (this.authService.currentUserSignal()?.role != 'admin') {
      this.router.navigate(['/dashboard']);
    }
  }

  fetchUsers($event?: TableLazyLoadEvent) {
    if (this.companyId != "" ) {
      this.usersService.getAllUsersForCompany(this.companyId, $event?.first || 0, $event?.rows || 5).then((userData: any) => {
        this.totalRecords = userData.totalItems;
        this.userList = userData.items;
      });
    }
  }

  showAddEditDialog(add: boolean, input: any) {
    if (add) {
      this.isAddDialogVisible = true;
      this.companyId = input;
    } else {
      this.isEditDialogVisible = true;
      this.selectedUser = input;
    }
  }

  onUserEdited() {
    this.fetchUsers();
    this.isEditDialogVisible = false;
    this.messageService.add({ severity: 'success', summary: 'Bearbeiten erfolgreich', detail: "Person wurde bearbeitet" });
  }

  onUserAdded() {
    this.fetchUsers();
    this.isAddDialogVisible = false;
    this.messageService.add({ severity: 'success', summary: 'Anlegen erfolgreich', detail: "Person wurde angelegt" });
  }

  deleteUser(id: string) {
    this.usersService.getUserById(id).then((user: any) => {
      if (user) {
        user = user[0];
        this.confirmationService.confirm({
            message: user.firstName+" "+user.lastName+" wirkllich löschen?",
            acceptLabel: "Ja",
            rejectLabel: "Nein",
            acceptButtonStyleClass: "p-button-danger",
            acceptIcon:"none",
            rejectIcon:"none",
            accept: () => {
              this.usersService.deleteUser(id).then(() => {
                this.fetchUsers();
                this.messageService.add({ severity: 'success', summary: 'Löschen bestätigt', detail: user.firstName+" "+user.lastName+" wurde gelöscht" });
              })
              .catch((error) => {
                console.log(error);
                this.messageService.add({ severity: 'error', summary: 'Fehler beim Löschen', detail: 'Das Löschen von ' + user.firstName + ' ' + user.lastName + ' ist fehlgeschlagen' });
              });
              
            }
        });
      }
    });
  }
}
