import { Component } from '@angular/core';
import { FooterComponent } from '../footer/footer.component';
import { NavbarComponent } from '../navbar/navbar.component';
import { DownloadComponent } from '../download/download.component';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'app-share-landing',
  standalone: true,
  imports: [FooterComponent, NavbarComponent, DownloadComponent, DialogModule],
  templateUrl: './share-landing.component.html',
  styleUrl: './share-landing.component.css'
})
export class ShareLandingComponent {

  isDownloadDialogVisible = false;

  provideDownloadInfo() {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
      window.open('https://play.google.com/store/apps/details?id=de.example.app.android', "_blank");
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      window.open('https://apps.apple.com/de/app/maemona/id6504430544', "_blank");
    } else {
      this.isDownloadDialogVisible = true;
    }
  }

}
