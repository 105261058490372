import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { NgIf } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { PrimeNGConfig } from 'primeng/api';
import { NavbarComponent } from './components/website/navbar/navbar.component';
import { CookieConsentComponent } from './components/website/cookie-consent/cookie-consent.component';
import { UsersService } from './services/users.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterModule,
    ButtonModule,
    NgIf,
    HttpClientModule,
    NavbarComponent,
    CookieConsentComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {

  constructor(private router: Router, private config: PrimeNGConfig, private route: ActivatedRoute) {}
  
  title = 'memona';
  authService: AuthService = inject(AuthService);
  usersService: UsersService = inject(UsersService);
  httpClient: HttpClient = inject(HttpClient);
  
  logout() {
    localStorage.setItem("userId", '');
    this.authService.currentUserSignal.set(null);
    this.router.navigate(['/app']);
  }

  onActivate() {
    window.scroll(0,0);
  }

  ngOnInit(): void {
    const userId = localStorage.getItem("userId");
    this.usersService.getUserById(userId!).then((user: any) => {
      this.authService.currentUserSignal.set(user[0]);
    });

    var appLink = ""
    if (/android/i.test(navigator.userAgent)) {
      appLink = 'https://play.google.com/store/games?hl=de&pli=1'
    }

    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      appLink = 'https://itunes.apple.com/de/app/amazon/id348712880'
    }

    this.route.queryParams.subscribe(params => {
      if (params['id']) {
        const id = params['id'];
        const maemonaURL = `maemona://${id}`;
        setTimeout(() => {
          if (!document.hidden) {
            window.location.href = appLink;
          }
        }, 100);
        window.location.replace(maemonaURL);
      }
    });
    this.config.setTranslation({
      dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
      dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
      monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
      today: 'Heute',
      clear: 'Löschen',
      emptySearchMessage: 'Keine Einträge gefunden'
  });
  }

}
